import BaseService from './BaseService';
import { Http } from '../Http';
import { Response, FormModel } from '../Redux/Models';

export default class FormService extends BaseService {
	public static update(locationId: number, data: Object = {}): Promise<Response<FormModel>> {
		FormService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch(`locations/${locationId}/forms`, data, { cancelToken: FormService.source?.token })
				.then((res) => {
					let data = new Response<FormModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static show(companyId: any, locationId: number, params: Object = {}): Promise<Response<FormModel>> {
		FormService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(`/companies/${companyId}/locations/${locationId}/forms`, { params, cancelToken: FormService.source?.token })
				.then((res) => {
					let data = new Response<FormModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getFormResponse(taskId: number, params: Object = {}): Promise<Response<FormModel>> {
		FormService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(`/service-history/${taskId}/form`, { params, cancelToken: FormService.source?.token })
				.then((res) => {
					let data = new Response<FormModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
