import { StateModel } from '../Models';

let storage = localStorage
if (process.env.NODE_ENV === 'development') storage = sessionStorage

export default class Stroage {
	public static LoadState = (): StateModel => {
		try {
			const sessionState: any = storage.getItem('state') || {};
			const state: StateModel = Object.assign({}, JSON.parse(sessionState));
			return state;
		} catch (err) {
			const params: StateModel = new StateModel();
			return Object.assign({}, params);
		}
	};

	public static SaveState = (state: StateModel): void => {
		try {
			storage.clear();
			const sessionState = {
				Auth: state.Auth,
				Config: state.Config
			};
			storage.setItem('state', JSON.stringify(sessionState));
		} catch (err) {
			// console.error(err);
		}
	};
}
