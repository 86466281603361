import { Badge, BadgeProps, Dialog, styled as MuiStyle, Theme } from '@mui/material';
import { ThemeProps } from 'styled-components';

export const MuiStyledBadge = MuiStyle(Badge, { shouldForwardProp: (prop) => prop !== 'active' })<BadgeProps & { active: boolean }>(
	({ theme, active = false }: ThemeProps<Theme> & { active: boolean }) => ({
		'& .MuiBadge-badge': {
			'backgroundColor': active ? '#44b700' : 'red',
			'color': active ? '#44b700' : 'red',
			'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
			'minWidth': '15px',
			'height': '15px',
			'borderRadius': '15px',
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: 'ripple 1.2s infinite ease-in-out',
				border: '1px solid currentColor',
				content: '""'
			}
		},
		'.MuiBox-root': {
			position: 'absolute',
			width: '100%',
			top: 0,
			left: 0,
			height: '100%',
			background: '#00000061',
			borderRadius: '50%',
			color: '#fff',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column'
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0
			}
		}
	})
);

export const AutoHeightScrollModalContainer = MuiStyle(Dialog)(({ theme }: ThemeProps<Theme>) => ({
	'& .MuiDialog-paper': {
		minWidth: 800,
		padding: 30,
		maxHeight: 'initial',
		[theme.breakpoints.down('md')]: {
			minWidth: 600
		},
		[theme.breakpoints.down('sm')]: {
			minWidth: '100%',
			padding: 20
		}
	},
	'&.MuiDialog-root': {
		overflow: 'auto'
	},
	'& .MuiDialog-container': {
		padding: 30,
		height: 'auto',
		[theme.breakpoints.down('sm')]: {
			padding: 20
		}
	},
	'& .closeBtnHeader': {
		'position': 'relative',
		'& .closeBtn': {
			cursor: 'pointer',
			position: 'absolute',
			top: '-20px',
			right: '-20px',
			[theme.breakpoints.down('sm')]: {
				top: '-16px',
				right: '-16px'
			}
		}
	}
}));
