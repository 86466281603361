import { Component, ReactNode } from "react";
import { WhiteLogo } from "Helpers/Images";
import { AuthLayoutContainer } from "./styled";
import { MuiBox, MuiButton } from "Components/MUI";
import { Redirect, Route, Switch } from "react-router-dom";
import { Map } from "Helpers/Array";
import { RouteInterface } from "Routes";
import { Props } from "Redux/Models";
import { Theme } from "@mui/material/styles";

export default class AuthLayout extends Component<Props> {
  public render(): ReactNode {
    const { childrens, ...restProps }: Props = this.props;
    return (
      <AuthLayoutContainer>
        <MuiBox
          display="flex"
          justifyContent="space-between"
          sx={[
            (theme: Theme) => ({
              width: "80%",
              margin: "0 auto",
              position: "relative",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
              "& img": {
                width: 155,
              },
              "& button": {
                boxShadow: "none",
              },
            }),
          ]}
        >
          <img alt="logo" src={WhiteLogo} />
          <MuiButton
            loading={false}
            variant="outlined"
            fullWidth={false}
            className="bookADemoBtn"
          >
            Book a demo
          </MuiButton>
        </MuiBox>

        <MuiBox
          sx={{
            position: "relative",
            mt: 0,
            height: "100%",
            minHeight: "inherit",
            display: "flex",
            alignItems: "center",
          }}
        >
          <MuiBox className="AuthFormMainWrapper">
            <Switch>
              {Map(childrens, (route: RouteInterface, i) => {
                return (
                  <Route
                    key={i}
                    exact={route.exact}
                    path={route.path}
                    render={(rProps: any) =>
                      !route.redirect ? (
                        <route.component
                          {...restProps}
                          {...rProps}
                          childrens={route.childrens}
                        />
                      ) : (
                        <Redirect to={route.redirect || "/"} />
                      )
                    }
                  />
                );
              })}
            </Switch>
          </MuiBox>
        </MuiBox>
      </AuthLayoutContainer>
    );
  }
}
