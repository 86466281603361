import { Component, MouseEvent, ReactNode } from 'react';
import { MuiMenu, MuiMenuItem, MuiIconButton, MuiBadge, MuiListItemAvatar, MuiListItemText, MuiTypography, MuiLink } from 'Components/MUI';
import { Theme } from '@mui/material';
import { NotificationsIcon } from 'Helpers/Icons';
import { Props, States } from 'Redux/Models';
import { Link } from 'react-router-dom';
import { Logout } from 'Redux/Actions';
import { StyleMuiAvatar } from 'Components/styled';
import { IconLogo } from 'Helpers/Images';
import { TimeSince } from 'Helpers/String';
import { BroadCastService } from 'Services';

export default class Notification extends Component<Props, States> {
	public state: States = {
		anchorEl: null,
		notificationCount: null,
	};
	public handleDropdownOpen = (event: MouseEvent<HTMLButtonElement>) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	public handleDropdownClose = () => {
		this.setState({ anchorEl: null });
	};

	public handleLogout = (): void => {
		const { history } = this.props;
		this.props.dispatch(Logout());
		history.push('/login');
	};

	public viewAllNotification = () => {
		const { history } = this.props;
		history.push('/notifications');
		BroadCastService.notificationSeen('all').then(resp => {
			if (resp.isSuccess()) {
				this.setState((prevState: States) => {
					prevState.notificationCount = resp.data.unseen;
					return prevState;
				})
			}
		})
	};

	public render(): ReactNode {
		const { anchorEl, notificationCount } = this.state;
		const { notification, notificationClick } = this.props;
		return (
			<>
				<MuiIconButton
					size="large"
					aria-label="show 17 new notifications"
					id="notificationMenu"
					aria-haspopup="true"
					onClick={this.handleDropdownOpen}
					sx={[
						(theme: Theme) => ({
							background: 'transparent !important',
							boxShadow: 'none !important',
							color: theme.palette.secondary.dark,
							minWidth: 'auto',
							border: '1px solid #00000021',
							padding: 1,
							mx: 2,
							[theme.breakpoints.down('sm')]: {
								padding: '2px',
								ml: '10px',
								mr: '12px'
							}
						})
					]}
				>
					<MuiBadge badgeContent={notificationCount !== null ? notificationCount : (notification?.total > 99 ? '99+' : notification?.total)} color="primary">
						<NotificationsIcon />
					</MuiBadge>
				</MuiIconButton>
				<MuiMenu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleDropdownClose}
					MenuListProps={{
						'aria-labelledby': 'notificationMenu'
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					sx={[
						(theme: Theme) => ({
							maxWidth: 300,
							minWidth: 300,
							[theme.breakpoints.up(400)]: {
								maxHeight: 300,
							},
							'& .MuiPaper-root': {
								minWidth: 300,
								maxHeight: 300,
								[theme.breakpoints.up(400)]: {
									position: 'relative',
									overflow: 'visible',
									top: '60px !important',
									border: '1px solid #dfdfdf',
									maxHeight: 'fit-content',
									'&::before, &::after': {
										content: '""',
										position: 'absolute',
										top: '-10px',
										right: '10px',
										borderBottom: '10px solid #dfdfdf',
										borderRight: '10px solid transparent',
										borderLeft: '10px solid transparent',
									},
									'&::after': {
										top: '-9px',
										right: '10px',
										borderBottom: '10px solid #fff',
									},
									'& .MuiList-root': {
										overflow: 'hidden',
										overflowY: 'auto',
										maxHeight: 400,
									}
								}
							},
							'& a': {
								textDecoration: 'none'
							}
						})
					]}
				>
					{notification?.items && notification?.items.length > 0 ? notification?.items?.map((item: any, index: number) => (
						<MuiMenuItem
							key={index}
							onClick={() => notificationClick(item, this.handleDropdownClose)}
							sx={{
								color: '#343434',
								alignItems: 'flex-start',
								padding: '5px 8px',
								backgroundColor: 'transparent',
								'&:hover': {
									backgroundColor: 'transparent',
								},
								'& .MuiTouchRipple-root': {
									display: 'none',
								},
								'&:nth-of-type(odd)': {
									'& .notificationListContent': {
										backgroundColor: item.seen ? '#3fb3d021' : '#f5f5f5',
										'&:hover': {
											backgroundColor: item.seen ? '#3fb3d021' : '#d4ebf1',
										}
									}
								}
							}}
						>
							<MuiLink
								component={item.link ? Link : 'div'}
								to={'#'}
								className='notificationListContent'
								display="flex"
								sx={{
									width: '100%',
									padding: '10px 12px',
									backgroundColor: item.seen && '#3fb3d021',
									borderRadius: '5px',
									transition: '0.4s',
									'&:hover': {
										backgroundColor: item.seen ? '#3fb3d021' : '#d4ebf1',
										transition: '0.4s',
									}
								}}
							>
								<MuiListItemAvatar>
									<StyleMuiAvatar>
										<img alt="avatar-logo" src={IconLogo} />
									</StyleMuiAvatar>
								</MuiListItemAvatar>
								<MuiListItemText
									primary={
										<MuiTypography
											variant="subtitle2"
											sx={{
												fontWeight: 600,
												lineHeight: '18px',
												color: '#141414',
												whiteSpace: 'break-spaces',
												display: '-webkit-box',
												overflow: 'hidden',
												WebkitBoxOrient: 'vertical',
												WebkitLineClamp: '2',
												wordBreak: 'break-word',
											}}
										>
											{item.title || ''}
										</MuiTypography>
									}
									secondary={
										<>
											<MuiTypography
												variant="caption"
												sx={{
													fontWeight: 400,
													fontSize: '12px',
													margin: '5px 0 0 0',
													lineHeight: 1,
													whiteSpace: 'break-spaces',
													display: '-webkit-box',
													overflow: 'hidden',
													WebkitBoxOrient: 'vertical',
													WebkitLineClamp: '2',
													wordBreak: 'break-word',
												}}
											>
												{item.body || ''}
											</MuiTypography>
											<MuiTypography
												variant="caption"
												sx={{
													fontWeight: 400,
													whiteSpace: 'break-spaces',
													fontSize: '10px',
													margin: '10px 0 0 0',
													lineHeight: 1
												}}
											>
												{TimeSince(new Date(item.createdAt!)) || 'Just now'}
											</MuiTypography>
										</>
									}
								/>
							</MuiLink>
						</MuiMenuItem>
					)) : (
						<MuiMenuItem disabled sx={{
							justifyContent: 'center',
							opacity: '1 !important'
						}}>
							No Notifications
						</MuiMenuItem>
					)}
					{notification?.items.length > 0 && <MuiMenuItem
						onClick={this.handleDropdownClose}
						sx={{
							fontSize: 16,
							padding: '5px 8px',
							backgroundColor: 'transparent',
							'&:hover': {
								backgroundColor: 'transparent',
							},
						}}
					>
						<MuiLink
							component={Link}
							to="#"
							onClick={() => this.viewAllNotification()}
							sx={{
								margin: '0 auto',
								fontWeight: 700,
								width: '100%',
								textAlign: 'center',
								padding: '10px 12px',
								backgroundColor: '#3fb3d021',
								borderRadius: '5px',
								transition: '0.4s',
								'&:hover': {
									backgroundColor: 'primary.dark',
									color: 'primary.contrastText',
									transition: '0.4s',
								}
							}}
						>
							View All
						</MuiLink>
					</MuiMenuItem>}
				</MuiMenu>
			</>
		);
	}
}
