import { GetDateTime, GetHourDifference } from 'Helpers/String';
import { INDUCTION_TYPES } from 'Redux/Models/Service.History.Models';
import { BaseStateModel, DataTableModel, UploadModel, /* ContractortModel, LocationModel, ServiceModel, */ } from '../Models';


export class ReportListState extends BaseStateModel {
	public modalOpen: boolean = false;
	public tab: number = 0;
	public files: { insurences: UploadModel[], licenses: UploadModel[] } | null = null
	public allHeaders = [
		{ key: 'task', label: 'Task', isSort: true },
		{ key: 'service.name', label: 'Service Type', isSort: false, },
		{ key: 'location.name', label: 'Location', isSort: false, },
		{ key: 'creator.company.name', label: 'Company', isSort: false, },
		{ key: 'assignedUser.name', label: 'Name', isSort: false, render: (item: any) => `${item.assignedUser.firstName || ''} ${item.assignedUser.lastName || ''}` },
		{ key: 'checkIn', label: 'Check In', isSort: false, render: (item: any) => GetDateTime(item.checkIn) },
		{ key: 'checkOut', label: 'Check Out', isSort: false, render: (item: any) => GetDateTime(item.checkOut) },
		{ key: 'timeOnsite', label: 'Time Onsite', isSort: false, render: (item: any) => GetHourDifference(item.checkIn, item.checkOut) },
		{ key: 'induction', label: 'Induction', isSort: true },

		{ key: 'assignedLocations.0.name', label: 'Location', isSort: false, render: (item: any) => item?.assignedLocations?.[0]?.name },
		{ key: 'parents', label: 'Contractor(s)', isSort: false, render: (item: any) => item.parents?.map((itm: any) => itm.name)?.join(',') },
		{ key: 'company.name', label: 'Company', isSort: false, },
		{ key: 'name', label: 'Employee Name', isSort: false, render: (item: any) => `${item.firstName || ''} ${item.lastName || ''}` },
		{ key: 'service', label: 'Service', isSort: false, render: (item: any) => item.services?.[0]?.name },
	]

	public allFilters = [
		{ key: 'serviceId', name: 'Services', multiple: true, items: [] },
		{ key: 'locationId', name: 'Locations', multiple: true, items: [] },
		{
			key: 'induction', name: 'Induction Type', multiple: true,
			items: [
				{ label: 'Passed', value: INDUCTION_TYPES.PASSED },
				{ label: 'Failed', value: INDUCTION_TYPES.FAILED },
			],
		},
		{
			key: 'checkInOut', name: 'Check In Status', multiple: true,
			items: [
				{ label: 'Checked In', value: 'checkIn' },
				{ label: 'Checked Out', value: 'checkOut' },
			],
		},
		{
			key: 'license', name: 'License Status', multiple: true,
			items: [
				{ label: 'Expired', value: 'EXPIRED' },
				{ label: 'Running', value: 'RUNNING' },
			],
		},
	]

	public dataTable: DataTableModel = {
		searchable: true,
		pagination: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'service-history?with[]=creator.company&with[]=assignedUser&with[]=service&with[]=location',
		headers: this.allHeaders.filter((d, i) => i < 9),
		filters: this.allFilters.filter((d, i) => i < 3),
	};
}



export class ContractorProfileState extends BaseStateModel {
	public loading: boolean = false;
	public edit: boolean = false;
	public tab: number = 0;

	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'locations',
		headers: [
			{ key: 'name', label: 'Building Name/Referance', isSort: true },
			{ key: 'address', label: 'Address', isSort: true, style: { minWidth: '200px' } },
			{ key: 'company', label: 'Company name', isSort: true },
			{ key: 'contactName', label: 'Contact Name', isSort: true },
			{ key: 'contactEmail', label: 'Contact Email', isSort: true },
			{ key: 'contactPhone', label: 'Contact Phone', isSort: true },
		]
	};

	public empDataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'employees',
		headers: [
			{ key: 'firstName', label: 'First Name', isSort: true },
			{ key: 'lastName', label: 'Last Name', isSort: true },
			{ key: 'email', label: 'Email', isSort: true },
			{ key: 'phone', label: 'Phone', isSort: true },
		]
	};
}