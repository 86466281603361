import { CancelTokenSource } from '../../Http';
import { History, Location } from 'history';

import {
  LoadUserModel,
  UserModel,
  AuthUserModel,
  CompanyModel,
  ContractortModel,
  EmployeeModel,
  NotesModel,
} from './User.Models';

export class Props<T = unknown> {
  public user!: AuthUserModel;
  public title?: string;
  public history!: History<T>;
  public location!: Location<T>;
  public dispatch!: Function;
  public deviceToken!: string;
  [key: string]: any;
}

export class States {
  [key: string]: any;
}

export class Response<T extends {}> {
  public data!: T;
  public status!: number;
  public message!: string;
  public success!: boolean;
  public constructor(params = {}) {
    Object.assign(this, params);
  }

  public getStatus(): number {
    return this.status;
  }

  public isSuccess(): boolean {
    return this.success;
  }

  public getData(): T {
    return this.data;
  }
}

export class DataList<T extends {}> {
  public items!: Array<T>;
  public total!: number;
  public hasItem!: boolean;
}

export class ResponseList<T extends {}> {
  public data!: DataList<T>;
  public status!: number;
  public message!: string;
  public success!: boolean;
  public constructor(params = {}) {
    Object.assign(this, params);
  }

  public getStatus(): number {
    return this.status;
  }

  public isSuccess(): boolean {
    return this.success;
  }

  public getItems(): Array<T> {
    return this.data.items;
  }

  public getTotal(): number {
    return this.data.total;
  }

  public getData(): DataList<T> {
    return this.data;
  }
}

export class Options {
  constructor(params: Options = {}) {
    this.params = params;
  }
  [key: string]: any;
  public params?: Object;
  public root?: string;
  public sources?: CancelTokenSource;
}

export {
  LoadUserModel,
  UserModel,
  AuthUserModel,
  CompanyModel,
  ContractortModel,
  EmployeeModel,
  NotesModel,
};

export {
  InitialAuthStateModel,
  BaseStateModel,
  BaseModel,
  StateModel,
  DataTableActionModel,
  DataTableHeaderModel,
  DataTableModel,
  ACTION,
  GENDER,
  DashboardMetricModel,
  UploadModel,
  ActivityListModel,
} from './Base.Models';

export {
  RoleModel,
  PermissionModel,
  ModuleModel,
  RoleHasPermission,
  PERMISSION,
  MODULE,
  ROLE,
  RoleDetailModel,
  ModuleWithPermission,
  AddRoleModel
} from './Role.Models';

export { FormModel, Field, FIELD, FORMTYPE, Option } from './Form.Models';

export { LocationModel, LocationSearchModel } from './Location.Models';

export { ServiceModel } from './Service.Models';
export { ServiceHistoryModel } from './Service.History.Models';
export { BroadCastModel } from './BroadCast.Models';

export { SubscriptionModel, PlanModel } from './Subscription.Models';

export { InvoiceModel } from './Stripe.Models';
