import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { FileUploadIcon } from 'Helpers/Icons';
import { Component, ReactNode } from 'react';
export default class MuiUploadButton extends Component<LoadingButtonProps> {
	public render(): ReactNode {
		const { fullWidth = true, children } = this.props;
		return (
			<LoadingButton startIcon={<FileUploadIcon />} {...this.props} fullWidth={fullWidth}>
				{children}
			</LoadingButton>
		);
	}
}
