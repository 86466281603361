import { BaseModel } from './Base.Models';

export class ServiceHistoryModel extends BaseModel {
	public id?: number;
	public service?: string
	public location?: string
	public user?: string
	public task?: string
}

export enum INDUCTION_TYPES {
	PASSED = 'PASSED',
	FAILED = 'FAILED',
}