import { Badge, BadgeProps, Avatar, styled as MuiStyle, Theme } from '@mui/material';
import { ThemeProps } from 'styled-components';

export const TableListAvatar = MuiStyle(Avatar)(({ theme }: ThemeProps<Theme>) => ({
	textTransform: 'uppercase',
	fontWeight: 600,
	fontSize: '13px',
	width: 35,
	height: 35,
	background: theme.palette.primary.main
}));

export const StyleMuiAvatar = MuiStyle(Avatar)(({ theme }: ThemeProps<Theme>) => ({
	'background': theme.palette.primary.light,
	'color': theme.palette.secondary.dark,
	'overflow': 'hidden',
	'& img': {
		width: '100%',
		height: '100%',
		objectFit: 'cover'
	}
}));

export const MuiStyledBadge = MuiStyle(Badge, { shouldForwardProp: (prop) => prop !== 'active' })<BadgeProps & { active: boolean }>(
	({ theme, active = false }: ThemeProps<Theme> & { active: boolean }) => ({
		'& .MuiBadge-badge': {
			'backgroundColor': active ? '#44b700' : 'red',
			'color': active ? '#44b700' : 'red',
			'boxShadow': `0 0 0 2px ${theme.palette.background.paper}`,
			'minWidth': '15px',
			'height': '15px',
			'borderRadius': '15px',
			'&::after': {
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				borderRadius: '50%',
				animation: 'ripple 1.2s infinite ease-in-out',
				border: '1px solid currentColor',
				content: '""'
			}
		},
		'.MuiBox-root': {
			position: 'absolute',
			width: '100%',
			top: 0,
			left: 0,
			height: '100%',
			background: '#00000061',
			borderRadius: '50%',
			color: '#fff',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column'
		},
		'@keyframes ripple': {
			'0%': {
				transform: 'scale(.8)',
				opacity: 1
			},
			'100%': {
				transform: 'scale(2.4)',
				opacity: 0
			}
		}
	})
);
