import { MuiBox, MuiButton, MuiGrid, MuiIconButton, MuiInputField } from 'Components/MUI';
import { Component, ReactNode } from 'react';
import { DialogTitle, DialogContent, DialogProps, Theme } from '@mui/material';
import { StyleDialog } from './styled';
import { Formik, FormikHelpers } from 'formik';
import { EmailValidator } from 'Helpers/Validator';
import { AuthUserModel, LocationModel, ROLE } from 'Redux/Models';
import { LocationQRDialogState } from 'Redux/States';
import { LocationService } from 'Services';
import { GetImage } from 'Helpers/String';
import { CloseIcon } from 'Helpers/Icons';

class SendQRDialog extends Component<DialogProps & { location: LocationModel; user: AuthUserModel }, LocationQRDialogState> {
	public state: LocationQRDialogState = new LocationQRDialogState();

	public handleSubmit = (values: { email: string }, actions: FormikHelpers<{ email: string }>) => {
		const { onClose } = this.props;
		if (!this.props.location.id) {
			return;
		}
		actions.setSubmitting(true);
		LocationService.sendQrCode(this.props.location.id, values)
			.then((resp) => {
				if (resp.isSuccess()) {
					if (onClose) {
						onClose(resp.getData(), 'backdropClick');
					}
				}
				actions.setSubmitting(true);
			})
			.catch(() => actions.setSubmitting(false));
	};

	public render(): ReactNode {
		const { model } = this.state;
		const { user, location, ...restProps } = this.props;
		const { onClose } = this.props;
		return (
			<StyleDialog {...restProps}>
				<DialogTitle>
					<MuiBox
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						className='noPrintElement'
						sx={{
							width: '100%'
						}}
					>
						Location QR
						{onClose &&
							<MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
								<CloseIcon />
							</MuiIconButton>
						}
					</MuiBox>
				</DialogTitle>
				<DialogContent sx={{ mt: 1, pt: '8px !important' }}>
					<Formik initialValues={model} validationSchema={EmailValidator} onSubmit={this.handleSubmit}>
						{(props) => (
							<MuiBox component="form" noValidate autoComplete="off" onSubmit={props.handleSubmit}>
								<MuiGrid container spacing={2}>
									<MuiGrid id="QR-CODE" item xs={12} mb={2} textAlign="center" className='qrImgWrap'
										sx={[
											(theme: Theme) => ({
												'& img': {
													width: '100%',
													height: 'auto',
													maxWidth: 350,
													maxHeight: 350,
												}
											})
										]}
									>
										<img alt="qrcode" src={GetImage(location.qr)} />
									</MuiGrid>

									{user.hasRole(ROLE.CLIENT) && (
										<MuiGrid item xs={12} mb={2} className='noPrintElement'>
											<MuiInputField
												name="email"
												label="Email"
												onChange={props.handleChange}
												value={props.values.email || ''}
												onBlur={props.handleBlur}
												error={props.touched.email && !!props.errors.email}
												helperText={props.touched.email ? props.errors.email : ''}
											/>
										</MuiGrid>
									)}

									<MuiGrid item xs={!user.hasRole(ROLE.CLIENT) ? 12 : 6} className='noPrintElement'>
										<MuiButton variant="outlined" fullWidth={true} onClick={() => window.print()} color="primary">
											Print
										</MuiButton>
									</MuiGrid>

									{user.hasRole(ROLE.CLIENT) ? (
										<MuiGrid item xs={6} className='noPrintElement'>
											<MuiButton loading={props.isSubmitting} variant="contained" fullWidth={true} color="secondary" type="submit">
												Send
											</MuiButton>
										</MuiGrid>
									) : (
										''
									)}
								</MuiGrid>
							</MuiBox>
						)}
					</Formik>
				</DialogContent>
			</StyleDialog>
		);
	}
}
export default SendQRDialog;
