const isDev: boolean = process.env.NODE_ENV === 'development';

const local = 'http://localhost:3333'
// const liveURL = 'https://api.selr.intellexio.com' //staging
const liveURL = 'https://uat.api.selr.intellexio.com' //uat

export default class Configs {
	public static API_ROOT: string = isDev ? local : liveURL;
	public static API_URL: string = isDev ? `${local}/api/v1` : `${liveURL}/api/v1`
}

export const FIREBASE_CONFIG = {
	apiKey: isDev ? "AIzaSyA4YbOP3smnAqljZ6bzI1dEXs2rlmJBsbc" : "AIzaSyAgP9mnswxpS8Q6Y4z8_-M9dn_Sp9XTscc",
	authDomain: isDev ? "selr-cd7f9.firebaseapp.com" : "selr-b0a43.firebaseapp.com",
	projectId: isDev ? "selr-cd7f9" : "selr-b0a43",
	storageBucket: isDev ? "selr-cd7f9.appspot.com" : "selr-b0a43.appspot.com",
	messagingSenderId: isDev ? "72298064795" : "257129677205",
	appId: isDev ? "1:72298064795:web:74f44fbb3ac6e03c264f3e" : "1:257129677205:web:c6915d313a6df18d0a369c",
	measurementId: isDev ? "G-SGLZT95P7P" : "G-E48WKPKYJ6",
}

export const vapidKey = isDev ? 'BLar8kc068YQRb7fPdFOzTHfsX2c4Lw5Md4KKEJnVMO5GN0Tx68GxAppF6H9Ou75xVyskVZJ2gqRaed99RocYnc' : 'BEDAC7ID0nJ_QYxgGdnAibyIDzQSHahM6OyGu7MQXbvVZ-a2MMKAdXhhEAR1teaTzWZ7SkJwTeQSsXYBy--FqDk';

export const MB_TOKEN = isDev ? 'pk.eyJ1IjoiYXJuYWItcGF0cyIsImEiOiJjbGJwMmV5bHQwMHBnM3BzNWxiaXZxbjBqIn0.EtoCBp_pIMetUyfRVB-IAg' : 'pk.eyJ1Ijoic2VsciIsImEiOiJjbGN5bzJvMmkwYWVrM3Jub201dTJjMHY2In0.S7c9B5Lzv7zHBJUEC4lBvw';

export const MB_BASE_URL = 'https://api.mapbox.com/geocoding/v5/mapbox.places';