import { AppBarProps, Card, CSSObject, Grid, styled as MuiStyle, Theme } from '@mui/material';
import { ThemeProps } from 'styled-components';
import { MuiDrawer, MuiAppBar, MuiBox } from 'Components/MUI';
import { AuthBg, PlansBlueBg } from 'Helpers/Images';

const drawerWidth = 300;

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`
	}
});

export const AuthLeftContainer = MuiStyle(Grid)(({ theme }: ThemeProps<Theme>) => ({
	background: theme.palette.secondary.light,
	padding: '30px 70px',
	minHeight: '100vh',
	[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
		padding: 30
	}
}));

export const AuthSliderContainer = MuiStyle(Card)(({ theme }: ThemeProps<Theme>) => ({
	background: theme.palette.secondary.light,
	marginTop: 60,
	borderRadius: 20,
	padding: 30,
	boxShadow: theme.shadows[0]
}));

export const AuthRightContainer = MuiStyle(Grid)(({ theme }: ThemeProps<Theme>) => ({
	background: theme.palette.primary.light,
	padding: '30px 70px',
	minHeight: '100vh',
	[theme.breakpoints.down(theme.breakpoints.values.lg)]: {
		padding: 30
	},
	[theme.breakpoints.down(theme.breakpoints.values.md)]: {
		padding: '30px 70px'
	},
	[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
		padding: 30
	},
	[theme.breakpoints.down(400)]: {
		padding: '30px 15px'
	},
	'& .bookADemoBtn': {
		float: 'right'
	}
}));

export const AuthLayoutContainer = MuiStyle(MuiBox)(({ theme }: ThemeProps<Theme>) => ({
	padding: 30,
	minHeight: 'calc(100vh - 108px)',
	background: `url(${AuthBg})`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	position: 'relative',
	[theme.breakpoints.down("sm")]: {
		padding: 15,
	},
	'&::before': {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		backgroundColor: '#0000002b',
	},
	'& button': {
		boxShadow: 'none'
	},
	'& .AuthFormMainWrapper': {
		background: theme.palette.primary.main,
		width: 550,
		margin: '35px auto 10px auto',
		borderRadius: '10px',
		padding: 30,
		[theme.breakpoints.up("xl")]: {
			width: 650,
		},
		[theme.breakpoints.down("sm")]: {
			padding: 20,
			width: '100%',
		},
		'& .MuiButton-root': {
			backgroundColor: theme.palette.secondary.dark,
			color: theme.palette.primary.contrastText,
			'&:hover': {
				backgroundColor: theme.palette.secondary.dark,
				color: theme.palette.primary.contrastText,
			}
		},
		'& .MuiFormControl-root': {
			'& label': {
				color: '#505050 !important',
				'&.Mui-focused, &.MuiInputLabel-shrink': {
					color: '#505050 !important',
					background: theme.palette.primary.contrastText,
					padding: '0 8px',
					borderRadius: '4px',
					fontSize: 14,
				},
				'&.Mui-error': {
					color: `${theme.palette.error.main} !important`,
				}
			},
		},
		'& .MuiInputBase-root': {
			background: theme.palette.primary.contrastText,
			borderRadius: '5px',
			'& fieldset': {
				borderColor: '#ffffff',
				borderWidth: '1px',
			},
			'&.Mui-error': {
				'& fieldset': {
					borderColor: theme.palette.error.main,
				},
			}
		}
	},
}));

export const DashboardMainContainer = MuiStyle(MuiBox)(({ theme }: ThemeProps<Theme>) => ({
	'& .sidebarLogoImg': {
		width: '140px',
		margin: '0 auto'
	},
	'& header': {
		background: theme.palette.primary.contrastText,
		borderBottom: `1px solid ${theme.palette.grey[800]}`
	},
	'& .profileDrodownBtn': {
		border: `1px solid ${theme.palette.grey[800]}`,
		padding: '5px 10px'
	},
	'& .dashboardContentWrapper': {
		background: '#f3f6f9',
		minHeight: '100vh',
		[theme.breakpoints.down('sm')]: {
			padding: '30px 15px'
		}
	},
	'& .SidebarMainWrap': {
		'background': theme.palette.primary.main,
		'& .MuiPaper-root': {
			overflow: 'hidden',
			[theme.breakpoints.down('lg')]: {
				zIndex: 9999
			}
		}
	},
	'& .customAlertBox': {
		position: 'fixed',
		top: 15,
		right: 15,
		zIndex: 999999,
		background: theme.palette.primary.contrastText,
		padding: '15px',
		borderRadius: '5px',
		boxShadow: '0 0 15px #00000030',
		maxWidth: 400,
		minWidth: 250,
		overflow: 'hidden',
		'& .customAlertBoxContent': {
			position: 'relative',
			display: 'flex',
			alignItems: 'flex-start',
			justifyContent: 'space-between',
			'& h6': {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: 2
			},
			'& .MuiTypography-caption': {
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				display: '-webkit-box',
				WebkitBoxOrient: 'vertical',
				WebkitLineClamp: 2,
				color: theme.palette.secondary.main,
			}
		},
	},
	'& .showMoreLessBtn': {
		cursor: 'pointer',
		fontSize: 16,
		color: theme.palette.primary.dark,
		transition: '0.4s',
		fontWeight: 600,
		'&:hover': {
			color: theme.palette.secondary.dark,
			transition: '0.4s',
		},
		'& span': {
			display: 'flex',
			alignItems: 'center',
		}
	},
	'& .multiSelectFieldMainWrap': {
		'& .MuiInputBase-root': {
			paddingRight: 0,
			'& .MuiSelect-select': {
				'&.MuiSelect-multiple': {
					width: '100%',
					paddingRight: '45px',
				}
			}
		},
		'& .MuiSelect-icon': {
			position: 'absolute',
			right: 0,
		},
		'& .MuiInputAdornment-root': {
			marginLeft: 0,
			'& .MuiIconButton-root': {
				position: 'absolute',
				top: 0,
				right: 20,
				bottom: 0,
				height: 25,
				width: 25,
				transform: 'translate(0px, 38%)',
				padding: 0,
				'& .MuiSvgIcon-root': {
					width: 20,
				}
			},
		},
		'& fieldset': {
			paddingRight: 0,
		}
	},
	'& .plansTabsMainWrap': {
		'& .MuiTabs-root': {
			'& .MuiTabs-indicator': {
				display: 'none',
			}
		},
		'& .MuiTabs-flexContainer': {
			backgroundColor: theme.palette.primary.contrastText,
			boxShadow: '0 0 15px #00000021',
			borderRadius: '15px',
			width: 'fit-content',
			margin: '15px auto 25px auto',
			padding: '6px 4px',
		},
		'& .MuiTab-root': {
			borderRadius: '10px',
			fontWeight: 400,
			color: '#000',
			margin: '0 5px',
			'&.Mui-selected': {
				backgroundColor: theme.palette.primary.dark,
				color: theme.palette.primary.contrastText,
			}
		}
	},
	'& .priceOptionBoxMainWrap': {
		margin: '10px 0',
		'& .priceOptionBoxCntntWrap': {
			border: 'none',
			borderRadius: '20px',
			boxShadow: '0 0 15px #0000001a',
			padding: 20,
			textAlign: 'center',
			color: '#191A15',
			fontFamily: "'Montserrat', sans-serif",
			backgroundImage: `url(${PlansBlueBg})`,
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			height: '100%',
			display: 'flex',
			justifyContent: 'space-between',
			flexDirection: 'column',
			[theme.breakpoints.up("xl")]: {
				padding: 30,
			},
			'& h1, h2, h3, h4, h5, h6, p, span': {
				color: theme.palette.primary.contrastText,
			},
			'& h4': {
				fontSize: 18,
				background: theme.palette.primary.dark,
				color: theme.palette.primary.contrastText,
				width: '90%',
				margin: '-25px auto 0 auto',
				padding: '15px',
				borderRadius: '0 0 50px 50px',
				[theme.breakpoints.up("xl")]: {
					fontSize: 24,
					margin: '-35px auto 0 auto',
				}
			},
			'& h2': {
				fontSize: 42,
				[theme.breakpoints.up("xl")]: {
					fontSize: 55,
				},
				'& span': {
					fontSize: 16,
					fontWeight: 400,
					verticalAlign: 'text-top',
					margin: '0 4px 0 0',
					[theme.breakpoints.up("xl")]: {
						fontSize: 18,
					},
				}
			},
			'& p': {
				margin: '10px 0 15px 0',
				fontSize: 14,
				[theme.breakpoints.up("xl")]: {
					fontSize: 18,
				},
			},
			'& ul': {
				'& .MuiListItemIcon-root': {
					minWidth: 'auto',
					'& svg': {
						fontSize: 20,
						color: theme.palette.primary.main,
						[theme.breakpoints.up("xl")]: {
							fontSize: 25,
						},
					}
				},
				'& li': {
					padding: 0,
					'& h6': {
						fontSize: 16,
						[theme.breakpoints.up("xl")]: {
							fontSize: 18,
						},
					}
				}
			},
			'& .innerBoxWrap': {
				padding: '20px 15px',
				borderRadius: '10px',
				background: theme.palette.primary.contrastText,
				textAlign: 'left',
				'& h1, h2, h3, h4, h5, h6, p, span': {
					color: theme.palette.secondary.dark,
				},
				'& button': {
					fontSize: 16,
					transition: '0.5s',
					padding: '10px 15px',
					borderRadius: '15px',
					boxShadow: '0px 4px 9px rgb(0 0 0 / 5%)',
					background: theme.palette.primary.main,
					color: theme.palette.primary.contrastText,
					[theme.breakpoints.up("xl")]: {
						fontSize: 18,
					},
					'&:hover': {
						background: theme.palette.primary.dark,
						color: theme.palette.primary.contrastText,
						transition: '0.5s',
					}
				}
			}
		},
	},





	'& .autocompleteGroupBox': {
		'& .MuiAutocomplete-paper': {
			borderRadius: '0 0 5px 5px',
			boxShadow: '1px 4px 15px #00000033',
			border: '1px solid #dfdfdf',
		},
		'& .MuiAutocomplete-listbox': {
			maxHeight: 230,
			margin: '10px 0',
			padding: 0,
			'& li': {
				borderRadius: 4,
			},
			'& .MuiListSubheader-root': {
				background: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				lineHeight: '35px',
				fontSize: 14,
			},
			'& .MuiAutocomplete-groupUl': {
				margin: 8,
				padding: 0,
				'& li': {
					margin: '10px 0',
					background: '#f5f4f4',
				}
			}
		}
	}
}));

export const SidebarContentWraper = MuiStyle(MuiBox)(({ theme }: ThemeProps<Theme>) => ({
	'& .sidebarMainListUl': {
		overflow: 'hidden',
		overflowY: 'auto',
		height: '100vh',
		padding: '8px 0 80px 0',
		margin: '3px 0 0 0',
		'&::-webkit-scrollbar-track': {
			backgroundColor: '#cfecf342'
		},
		'&::-webkit-scrollbar': {
			width: 7,
			height: 7,
			backgroundColor: '#cfecf342'
		},
		'&:hover': {
			'&::-webkit-scrollbar': {
				backgroundColor: '#cfecf342'
			}
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: theme.palette.primary.main
		}
	},
	'& li': {
		margin: '5px 8px',
		width: 'calc(100% - 16px)',
		borderRadius: '5px',
		'& a': {
			borderRadius: '5px',
			'&.active': {
				background: theme.palette.primary.dark,
				'& span': {
					fontWeight: 600,
					color: theme.palette.primary.contrastText,
				},
				'& svg': {
					color: theme.palette.primary.contrastText,
				}
			}
		},
		'& span': {
			fontFamily: "'Montserrat',sans-serif!important",
			color: '#262626',
			fontSize: 16,
		}
	},
}));

export const DrawerHeader = MuiStyle(MuiBox)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	...theme.mixins.toolbar
}));

interface AppBarPropsa extends AppBarProps {
	open?: boolean;
}

export const StyleAppBar = MuiStyle(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open'
})<AppBarPropsa>(({ theme, open }: any) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));

export const StyleDrawer = MuiStyle(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }: any) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

export const FormContainer = MuiStyle(MuiBox)(({ theme }: ThemeProps<Theme>) => ({
	'& .checkboxRadioContainer': {
		width: 'fit-content'
	},
	'& .chekboxRadioInlineLabelWrap': {
		'& .MuiFormControlLabel-root': {
			display: 'flex',
			color: theme.palette.secondary.dark
		}
	}
}));
