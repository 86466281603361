import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, UserModel, InvoiceModel, UploadModel } from '../Redux/Models';

export default class ClientService extends BaseService {
	public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<UserModel>> {
		let url: string = '/clients';
		if (options?.root) {
			url = options.root + url;
		}
		ClientService.initCancelToken();
		if (options?.source) {
			ClientService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new ResponseList<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getClientById(id: number, params: Object = {}): Promise<Response<UserModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/clients/' + id, { params, cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getInvoiceList(id: number, params: Object = {}): Promise<Response<Array<InvoiceModel>>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(`/clients/${id}/invoices`, { params, cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<Array<InvoiceModel>>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static deleteFile(id: number, uploadId: number): Promise<Response<UploadModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete(`/clients/${id}/uploads/${uploadId}`, {
				cancelToken: ClientService.source?.token
			})
				.then((res) => {
					let data = new Response<UploadModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static uploadFiles(id: number, params: Object = {}): Promise<Response<Array<UploadModel>>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post(`/clients/${id}/uploads`, params, { cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<Array<UploadModel>>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static invite(params: Object = {}): Promise<Response<UserModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/auth/invite', params, { cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static create(params: Object = {}): Promise<Response<UserModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/clients', params, { cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateStatus(id: number, params: Object = {}): Promise<Response<UserModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/clients/' + id, params, { cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static update(id: number, params: Object = {}): Promise<Response<UserModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch('/clients/' + id, params, { cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static verify(token: string, params: Object = {}): Promise<Response<UserModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch('/clients/verify/' + token, { params, cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static delete(id: number): Promise<Response<UserModel>> {
		ClientService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete('/clients/' + id, { cancelToken: ClientService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
