import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, BroadCastModel, ResponseList } from '../Redux/Models';

export default class BroadCastService extends BaseService {
	public static getList(params: Object = {}, options: Options = {}): Promise<Response<BroadCastModel[]>> {
		let url: string = '/broadcasts';
		if (options?.root) {
			url = options.root + url;
		}
		BroadCastService.initCancelToken();
		if (options?.source) {
			BroadCastService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: BroadCastService.source?.token })
				.then((res) => {
					let data = new Response<BroadCastModel[]>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getNotifications(params: Object = {}, options: Options = {}): Promise<ResponseList<BroadCastModel>> {
		let url: string = '/notifications';
		if (options?.root) {
			url = options.root + url;
		}
		BroadCastService.initCancelToken();
		if (options?.source) {
			BroadCastService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: BroadCastService.source?.token })
				.then((res) => {
					let data = new ResponseList<BroadCastModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static create(params: Object = {}): Promise<Response<BroadCastModel>> {
		BroadCastService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/broadcasts', params, { cancelToken: BroadCastService.source?.token })
				.then((res) => {
					let data = new Response<BroadCastModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static notificationSeen(params: Object = {}): Promise<Response<BroadCastModel>> {
		BroadCastService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/notifications/' + params, { cancelToken: BroadCastService.source?.token })
				.then((res) => {
					let data = new Response<BroadCastModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

}
