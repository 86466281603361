import { AuthUserModel, ROLE } from "Redux/Models";
import * as yup from "yup";

const EMAIL = yup
  .string()
  .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Enter a valid email")
  .required("Email is required")
  .max(150, "Email must be maximum 150 characters");

const PASSWORD = yup
  .string()
  .min(8, "Password should be of minimum 8 characters length")
  .max(18, "Password should not contain more than 18 characters ")
  .required("Password is required");

const CONFIRM_PASSWORD = yup
  .string()
  .required("Confirm password is required")
  .min(8, "Confirm password must be minimum 8 characters")
  .max(18, "Confirm password must be maximum 18 characters ")
  .oneOf([yup.ref("password"), null], "Both passwords must match");

const ROLE_NAME = yup
  .string()
  .max(100, "Role name should be maximum 100 characters")
  .required("Role name is required");

const FIRST_NAME = yup
  .string()
  .matches(/^\S*$/, "First name should not contain spaces")
  .required("First name is required")
  .nullable();

const LAST_NAME = yup
  .string()
  .matches(/^\S*$/, "Last name should not contain spaces")
  .required("Last name is required")
  .nullable();

const NAME = (name: string) =>
  yup
    .string()
    .matches(/^[A-Za-z.-]+(\s*[A-Za-z.-]+)*$/, `${name} is not valid`)
    .required(`${name} is required`)
    .nullable();

const STRING_REQUIRED = (name: string, length: number = 100) =>
  yup
    .string()
    .trim()
    .required(`${name} is required`)
    .max(length, `${name} should have maximum ${length} characters`);

const NUMBER_REQUIRED = (name: string) =>
  yup.number().required(`${name} is required`);

const PHONE_NUMBER = (name: string) =>
  yup
    .string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, `Invalid ${name}`)
    .min(10, `${name} should be minimum 10 digits`)
    .max(16, `${name} should be maximum 16 digits`)
    .required(`${name} is required`)
    .nullable();

export const LoginValidator = yup.object({
  email: EMAIL,
  password: PASSWORD,
});

export const EmailValidator = yup.object({
  email: EMAIL,
});

export const LinkValidator = yup.object({
  link: STRING_REQUIRED("Link", 150).optional().url().nullable(),
});

export const ChangePasswordValidator = yup.object({
  currentPassword: PASSWORD,
  password: PASSWORD,
  confirmPassword: CONFIRM_PASSWORD,
});

export const PasswordValidator = yup.object({
  password: PASSWORD,
  confirmPassword: CONFIRM_PASSWORD,
});

export const AddUserValidator = (user: AuthUserModel) =>
  user.hasRole(ROLE.CLIENT)
    ? yup.object({
      firstName: FIRST_NAME,
      lastName: LAST_NAME,
      email: EMAIL,
      phone: PHONE_NUMBER("Phone Number"),
      gender: STRING_REQUIRED("Gender"),
    })
    : yup.object({
      firstName: FIRST_NAME,
      lastName: LAST_NAME,
      email: EMAIL,
      phone: PHONE_NUMBER("Phone Number"),
      role: NUMBER_REQUIRED("Role"),
      gender: STRING_REQUIRED("Gender"),
    });

export const RegisterValidator = (isClient: boolean) =>
  isClient
    ? yup.object({
      firstName: FIRST_NAME,
      lastName: LAST_NAME,
      email: EMAIL,
      phone: PHONE_NUMBER("Phone number"),
      companyName: STRING_REQUIRED("Company name", 150),
      password: PASSWORD,
      confirmPassword: CONFIRM_PASSWORD,
      abn: yup.string().required('ABN is required')
        .test('number', 'ABN is not valid', (value: any) => {
          const regex = /^[0-9]+$/;
          if (value) {
            if (!regex.test(value)) {
              return;
            } else {
              return value;
            }
          }
        }).min(11, 'ABN must be minimum 11 digits').max(11, 'ABN must be maximum 11 digits'),
    })
    : yup.object({
      firstName: FIRST_NAME,
      lastName: LAST_NAME,
      email: EMAIL,
      phone: PHONE_NUMBER("Phone number"),
      companyName: STRING_REQUIRED("Company name", 150),
      password: PASSWORD,
      confirmPassword: CONFIRM_PASSWORD,
      abn: yup.string().nullable().notRequired(),
    });

export const AddRoleValidator = yup.object().shape({
  name: ROLE_NAME,
  permissions: yup.lazy((value) => {
    const a: any = {};
    if (value) {
      Object.keys(value).map((val) => {
        a[val] = yup.object().shape({
          VIEW: yup.boolean().when(['ADD', 'INVITE', 'UPDATE', 'DELETE'], {
            is: (ADD: boolean, INVITE: boolean, UPDATE: boolean, DELETE: boolean) => ADD || INVITE || UPDATE || DELETE,
            then: yup.boolean().required('required').oneOf([true], 'required'),
            otherwise: yup.boolean().optional(),
          }),
          ADD: yup.boolean().optional(),
          INVITE: yup.boolean().optional(),
          DELETE: yup.boolean().optional(),
          UPDATE: yup.boolean().optional(),
        })
        return a;
      })
    }
    return yup.object().shape(a)
  }),
});

export const ProfileValidator = (user: AuthUserModel) =>
  user.hasRole(ROLE.CLIENT)
    ? yup.object({
      firstName: FIRST_NAME,
      lastName: LAST_NAME,
      companyName: STRING_REQUIRED("Company name", 150),
      email: EMAIL,
      phone: PHONE_NUMBER("Phone Number"),
      gender: STRING_REQUIRED("Gender"),
    })
    : yup.object({
      firstName: FIRST_NAME,
      lastName: LAST_NAME,
      email: EMAIL,
      phone: PHONE_NUMBER("Phone Number"),
      gender: STRING_REQUIRED("Gender"),
    });

export const AddClientValidator = yup.object({
  firstName: FIRST_NAME,
  lastName: LAST_NAME,
  email: EMAIL,
  phone: PHONE_NUMBER("Phone Number"),
  gender: STRING_REQUIRED("Gender"),
});

export const InviteClientValidator = yup.object({
  companyName: STRING_REQUIRED("Company name", 150),
  firstName: FIRST_NAME,
  lastName: LAST_NAME,
  email: EMAIL,
  phone: PHONE_NUMBER("Phone number"),
  address: STRING_REQUIRED("Address"),
  abn: yup.string().required('ABN is required')
    .test('number', 'ABN is not valid', (value: any) => {
      const regex = /^[0-9]+$/;
      if (value) {
        if (!regex.test(value)) {
          return;
        } else {
          return value;
        }
      }
    }).min(11, 'ABN must be minimum 11 digits').max(11, 'ABN must be maximum 11 digits'),
});

export const ServiceAddValidator = yup.object({
  name: STRING_REQUIRED("Service name", 200),
  details: STRING_REQUIRED("Service details", 1000),
});

export const BroadCastAddValidator = yup.object({
  title: STRING_REQUIRED("Broadcast title", 100),
  description: STRING_REQUIRED("Broadcast description", 1000),
  roles: yup
    .array()
    .min(1, "User type is required")
    .required("User type is required"),
  users: yup.array().min(1, "User is required").required("User is required"),
});

export const LocationAddValidator = yup.object({
  name: STRING_REQUIRED("Location name/Reference", 150),
  address: STRING_REQUIRED("Location address", 1000),
  contactEmail: EMAIL,
  contactName: STRING_REQUIRED("Contact name", 150),
  contactPhone: PHONE_NUMBER("Contact phone number"),
  company: STRING_REQUIRED("Company name", 150),
  ownerName: STRING_REQUIRED("Management name", 150),
  ownerPhone: PHONE_NUMBER("Management phone number"),
  ownerEmail: EMAIL,
  logo: yup.mixed().notRequired().nullable().optional(),
});

export const FormValidator = yup.object({
  title: STRING_REQUIRED("Form name"),
  fields: yup.array().of(
    yup.object({
      title: STRING_REQUIRED("Title"),
      type: STRING_REQUIRED("Type"),
      value: yup.string().optional().nullable(),
      number: PHONE_NUMBER("Phone Number").notRequired(),
      options: yup.array().when("type", {
        is: (type: string) => type === "CHECKBOX" || type === "RADIO",
        then: yup.array().of(
          yup.object({
            value: STRING_REQUIRED("Answer"),
          })
        ),
      }),
    })
  ),
});

export const GeneralFormValidator = yup.object({
  generalName: STRING_REQUIRED("Site name"),
  generalAddress: STRING_REQUIRED("Site address"),
  generalDescription: STRING_REQUIRED("Description"),
});

export const GeneralCheckValidator = yup.object({
  required: yup.boolean(),
});

export const NotificationFormValidator = yup.object({
  notificationName: STRING_REQUIRED("Notification name"),
  notificationEmail: EMAIL,
  notificationPhone: PHONE_NUMBER("Phone number"),
});



export const AddServiceHistoryValidator = yup.object({
  service: STRING_REQUIRED("Service type"),
  location: STRING_REQUIRED("Location"),
  user: STRING_REQUIRED("User"),
  task: STRING_REQUIRED("Task", 150),
});

export const InviteContractorValidator = yup.object({
  email: EMAIL,
  phone: PHONE_NUMBER("Phone number"),
  contactName: STRING_REQUIRED("Contact name"),
  companyName: STRING_REQUIRED("Company name"),
  service: STRING_REQUIRED("Service type"),
  invite: STRING_REQUIRED("Invite message"),
  locations: yup
    .array()
    .min(1, "Location is required")
    .required("Location is required"),
});

export const InviteEmployeeValidator = yup.object({
  abn: yup.string().required('ABN is required')
    .test('number', 'ABN is not valid', (value: any) => {
      const regex = /^[0-9]+$/;
      if (value) {
        if (!regex.test(value)) {
          return;
        } else {
          return value;
        }
      }
    }).min(11, 'ABN must be minimum 11 digits').max(11, 'ABN must be maximum 11 digits'),
  service: yup.number().optional().nullable().notRequired(),
  companyName: yup.string().optional().nullable(),
  name: NAME("Employee name"),
  phone: PHONE_NUMBER("Phone Number").notRequired().optional(),
  email: EMAIL,
  locations: yup.array().nullable().optional().notRequired(),
});

export const NoteValidator = yup.object({
  notes: yup.string().nullable().optional(),
});

export const DocumentValidator = yup.object({
  expDate: yup.date().optional().nullable()
})

export const PlanEditValidator = yup.object({
  limit: yup.number().required('Location limit is required')
    .test('number', 'Limit is not valid', (value: any) => {
      const regex = /^[0-9]+$/;
      if (value) {
        if (!regex.test(value)) {
          return;
        } else {
          return value;
        }
      }
    }).typeError('Location limit must be a number')
})
