import { Box, SxProps } from '@mui/material';
import { Component, ReactNode } from 'react';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	sx: SxProps;
}

export default class MuiTabPanel extends Component<TabPanelProps> {
	public render(): ReactNode {
		const { children, value, index, sx, ...other }: TabPanelProps = this.props;
		return (
			<Box
				className='tabPanelMainWrap'
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
			>
				{value === index && <Box sx={sx}>{children}</Box>}
			</Box>
		);
	}
}
