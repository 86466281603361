import { Component } from 'react';
import {
	CloseIcon,
} from 'Helpers/Icons';
import {
	MuiBox,
	MuiIconButton,
	MuiTypography
} from 'Components/MUI';
import { Theme } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const maxTime = 10		//time in sec

interface Props {
	onClose: any
	notification: any
}

export default class CustomAlert extends Component<Props> {
	render() {
		const { notification, onClose } = this.props
		const { show, items } = notification
		if (show) setTimeout(() => onClose(), maxTime * 1000)

		return (
			<>
				{show &&
					<MuiBox className="customAlertBox">
						<MuiBox className="customAlertBoxContent">
							<MuiBox>
								<MuiTypography variant='subtitle2' color="secondary.dark"> {items[0].title} </MuiTypography>
								<MuiTypography variant='caption' my="5px"> {items[0].body} </MuiTypography>

								<MuiTypography variant='caption' sx={[
									(theme: Theme) => ({
										color: theme.palette.secondary.contrastText,
										fontSize: 12,
									})
								]}>Just Now</MuiTypography>
							</MuiBox>
							<MuiIconButton sx={{ p: 0, ml: 1, }} onClick={onClose} > <CloseIcon /> </MuiIconButton>
						</MuiBox>
						<MuiBox
							sx={{
								position: 'absolute',
								bottom: 0,
								left: 0,
								width: '100%',
								'& .MuiLinearProgress-root': {
									'height': 4,
									'& .MuiLinearProgress-bar': {
										backgroundColor: '#489baf',
										animation: `linearProgress ${maxTime}s linear infinite`,
										'&:hover': {
											animationPlayState: 'paused',
										},
									}
								},
								'@keyframes linearProgress': {
									'0%': {
										transform: 'translateX(0%)',
									},
									'100%': {
										transform: 'translateX(-100%)',
									}
								}
							}}
						>
							<LinearProgress variant="determinate" value={0} />
						</MuiBox>
					</MuiBox>
				}
			</>
		);
	}
}
