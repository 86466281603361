import { ConfigActionTypes, ConfigActions } from '../Actions';
import { InitialConfigStateModel } from '../Models/Base.Models';

const initialState: InitialConfigStateModel = {
	isToggled: true,
	roles: [],
	permissions: []
};

const Config = (state = initialState, action: ConfigActions): InitialConfigStateModel => {
	const { type, payload } = action;
	switch (type) {
		case ConfigActionTypes.ToggleMenu: {
			return {
				...state,
				isToggled: payload
			};
		}
		case ConfigActionTypes.LoadRoles: {
			return {
				...state,
				roles: payload
			};
		}
		case ConfigActionTypes.AddRole: {
			const roles = state.roles;
			roles.push(payload);
			return {
				...state,
				roles: roles
			};
		}
		case ConfigActionTypes.LoadPermissions: {
			return {
				...state,
				permissions: payload
			};
		}
		default: {
			return state;
		}
	}
};

export default Config;
