import { GetDateTime, GetHourDifference } from 'Helpers/String';
import { BaseStateModel, DataTableModel, LocationModel, LocationSearchModel, UploadModel } from 'Redux/Models';

export class LocationListState extends BaseStateModel {
	public selectedLocation?: LocationModel | null = null;
	public type: 'QR' | 'GUIDELINE' = 'QR';
	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'locations?with=owner',
		headers: [
			{ key: 'name', label: 'Building Name/Referance', isSort: true },
		],
		filters: []
	};
}

export class LocationAddState extends BaseStateModel {
	public loading: boolean = false;
	public open: boolean = false;
	public locationList: Array<LocationSearchModel> = [];
	public location: LocationModel = {
		name: '',
		selectedAddress: {},
		address: '',
		contactEmail: '',
		contactName: '',
		contactPhone: '',
		ownerEmail: '',
		company: '',
		ownerName: '',
		ownerPhone: '',
		logo: ''
	};
}

export class LocationQRDialogState extends BaseStateModel {
	public loading: boolean = false;
	public model: { email: string } = {
		email: ''
	};
}

export class DuidelineDialogState extends BaseStateModel {
	public loading: boolean = false;
	public guidelines: Array<UploadModel> = [];
	public model: LocationModel = {};
}

export class LocationDetailsState extends BaseStateModel {
	public loading: boolean = false;
	public editOpen: boolean = false;
	public tab: number = 0;
	public location: LocationModel = {
		name: '',
		address: '',
		contactEmail: '',
		contactName: '',
		contactPhone: '',
		ownerEmail: '',
		company: '',
		ownerName: '',
		ownerPhone: '',
		createdAt: '',
		uploads: [],
		newLogo: ''
	};
	public dataTable: DataTableModel = {
		searchable: true,
		pagination: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'service-history?with[]=creator.company&with[]=assignedUser&with[]=service&with[]=location',
		headers: [
			{ key: 'creator.company.name', label: 'Contractor Name', isSort: false, render: (item: any) => `${item.creator.firstName} ${item.creator.lastName}` },
			{ key: 'assignedUser.name', label: 'Employee Name', isSort: false, render: (item: any) => `${item.assignedUser.firstName} ${item.assignedUser.lastName}` },
			{ key: 'service.name', label: 'Service Type', isSort: false, },
			{ key: 'location.name', label: 'Location', isSort: false, },
			{ key: 'task', label: 'Task', isSort: true },
			{ key: 'checkIn', label: 'Check In', isSort: true, render: (item: any) => GetDateTime(item.checkIn) },
			{ key: 'checkOut', label: 'Check Out', isSort: true, render: (item: any) => GetDateTime(item.checkOut) },
			{ key: 'timeOnsite', label: 'Time Onsite', isSort: true, render: (item: any) => GetHourDifference(item.checkIn, item.checkOut) },
			{ key: 'induction', label: 'Induction', isSort: true },
		],
		filters: [
			{ key: 'serviceId', name: 'Service Type', items: [], multiple: true },
		]
	};
}
