export enum FIELD {
	TEXT = 'TEXT',
	TEXTAREA = 'TEXTAREA',
	CHECKBOX = 'CHECKBOX',
	RADIO = 'RADIO',
	CONTACT = 'CONTACT',
	FILE = 'FILE',
	SIGNATURE = 'SIGNATURE'
}

export enum FORMTYPE {
	CHECK_IN = 'CHECK_IN',
	CHECK_OUT = 'CHECK_OUT',
	SITE_INDUCTION = 'SITE_INDUCTION'
}

export class Option {
	[key: string]: any;
	// public id!: number;
	// public title!: string;
	public value!: string;
	public selected!: boolean;
}
export class Field {
	[key: string]: any;
	// public id!: number;
	public title!: string;
	public defaultValue!: string;
	public type!: FIELD | string;
	public value!: string;
	public required!: boolean;
	public number!: string;
	public options!: Array<Option>;
}

export class FormModel {
	public title!: string;
	public type!: FORMTYPE;
	public fields!: Array<Field>;
	public data?: any;
	public induction?: string;
}
