import { MuiBox, MuiButton, MuiGrid, MuiIconButton, MuiInputField } from 'Components/MUI';
import { Component, ReactNode } from 'react';
import { DialogTitle, DialogContent, DialogProps } from '@mui/material';
import { StyleDialog } from './styled';
import { Formik, FormikHelpers } from 'formik';
import { LinkValidator } from 'Helpers/Validator';
import { LocationModel, UploadModel } from 'Redux/Models';
import { DuidelineDialogState } from 'Redux/States';

import { DragDropFileUploader } from 'Components/DragDropFileUploader';
import { ALOW_UPLOAD_FORMATS } from 'Configs/Constants';
import { LocationService } from 'Services';
import { CloseIcon, MuiArrowForward } from 'Helpers/Icons';
import { Filter } from 'Helpers/Array';

class GuidelineDialog extends Component<DialogProps & { location: LocationModel }, DuidelineDialogState> {
	public state: DuidelineDialogState = new DuidelineDialogState();
	constructor(props: DialogProps & { location: LocationModel }) {
		super(props);
		this.state.model = props.location;
	}

	public componentDidMount(): void {
		this.getLocationGuidelines();
	}

	public getLocationGuidelines = (): void => {
		LocationService.getGuidelinesByLocation(this.props.location.id!).then((resp) => {
			if (resp.isSuccess()) {
				this.setState((prevState: DuidelineDialogState) => {
					prevState.guidelines = resp.getData();
					return prevState;
				});
			}
		});
	};
	public handleSubmit = (values: LocationModel, actions: FormikHelpers<LocationModel>) => {
		actions.setSubmitting(true);
		LocationService.update(this.props.location.id!, values)
			.then(() => {
				actions.setSubmitting(false);
			})
			.catch(() => actions.setSubmitting(true));
	};

	public handleFileDelete = (file: UploadModel) => {
		if (file) {
			LocationService.deleteGuideline(this.props.location.id!, file.id!).then((resp) => {
				if (resp.isSuccess()) {
					this.setState((prevState: DuidelineDialogState) => {
						prevState.guidelines = Filter(prevState.guidelines, (item) => item.id !== file.id)
						return prevState;
					});
				}
			});
		}
	};

	public handleFileChange = (files: Array<File>) => {
		if (files.length) {
			const formData = new FormData();
			for (const file of files) {
				formData.append('files[]', file);
			}
			LocationService.uploadGuideLine(this.props.location.id!, formData).then((resp) => {
				if (resp.isSuccess()) {
					this.setState((prevState: DuidelineDialogState) => {
						prevState.guidelines = [...prevState.guidelines!, ...resp.getData()];
						return prevState;
					});
				}
			});
		}
	};

	public openLink = (): void => {
		const { location } = this.props;
		if (location.link) {
			const a = document.createElement('a');
			a.href = location.link;
			a.target = '_blank';
			a.click();
		}
	};

	public render(): ReactNode {
		const { model, loading, guidelines } = this.state;
		const { location, ...restProps } = this.props;
		const { onClose } = this.props;

		return (
			<StyleDialog {...restProps}>
				<DialogTitle>
					Sefety Guideline
					{onClose &&
						<MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
							<CloseIcon />
						</MuiIconButton>
					}
				</DialogTitle>
				<DialogContent sx={{ mt: 1, pt: '8px !important' }}>
					<DragDropFileUploader files={guidelines} allowedTypes={ALOW_UPLOAD_FORMATS} loading={loading} onChange={this.handleFileChange} onDelete={this.handleFileDelete}></DragDropFileUploader>
					<Formik initialValues={model} validationSchema={LinkValidator} onSubmit={this.handleSubmit}>
						{(props) => (
							<MuiBox component="form" noValidate autoComplete="off" onSubmit={props.handleSubmit} sx={{ mt: 2 }}>
								<MuiGrid container spacing={2}>
									<MuiGrid item xs={12} mb={2}>
										<MuiInputField
											name="link"
											label="Link"
											onChange={props.handleChange}
											value={props.values.link || ''}
											onBlur={props.handleBlur}
											error={props.touched.link && !!props.errors.link}
											helperText={props.touched.link ? props.errors.link : ''}
											InputProps={{
												startAdornment: !props.errors.link && props.values.link && (
													<MuiIconButton onClick={this.openLink}>
														<MuiArrowForward />
													</MuiIconButton>
												)
											}}
										/>
									</MuiGrid>

									<MuiGrid item xs={12}>
										<MuiButton loading={props.isSubmitting} variant="contained" fullWidth={true} type="submit">
											Update
										</MuiButton>
									</MuiGrid>
								</MuiGrid>
							</MuiBox>
						)}
					</Formik>
				</DialogContent>
			</StyleDialog>
		);
	}
}
export default GuidelineDialog;
