import { TextField } from '@mui/material';
import { Component, ReactNode } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers';

class DatePickerProps {
	public label!: string;
	public inputFormat!: string;
	public value!: null | Date;
	public onChange!: any;
	public name!: string;
	public error?: boolean;
	public helperText?: string;
	public defaultValue?: Date | null;
	public onKeyDown?: any;
	public isDisabled?: boolean;
}

class MuiDatePicker extends Component<DatePickerProps> {
	public render(): ReactNode {
		const { error, helperText, onKeyDown, isDisabled } = this.props;
		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DesktopDatePicker
					disablePast
					disabled={isDisabled || false}
					{...this.props}
					InputProps={{ disabled: isDisabled, readOnly: isDisabled }}
					renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} error={error} helperText={helperText} fullWidth />}
				/>
			</LocalizationProvider>
		);
	}
}

export default MuiDatePicker;
