import Configs from 'Configs';

export const GetImage = (img?: string): string => {
	if (!img) {
		return '';
	}
	return Configs.API_ROOT + '/' + img;
};

export const getFullPath = (path?: string): string => {
	if (!path) {
		return '';
	}
	return Configs.API_ROOT + '/' + path;
};

export const GetDateTime = (date?: string | Date) => {
	if (!date) {
		return '';
	}
	const utcDate = new Date(date);
	return utcDate.toISOString().slice(0, 10) + ' ' + utcDate.toLocaleTimeString();
};

export const GetHourDifference = (startDate?: string | Date, endDate?: string | Date) => {
	if (startDate && endDate) {
		const timeStart = new Date(startDate).getTime();
		const timeEnd = new Date(endDate).getTime();
		const milliDiff = timeEnd - timeStart; //in ms
		const minDiff = milliDiff / 60 / 1000; //in minutes
		const hourDiff = milliDiff / 3600 / 1000; //in hours
		const result = ((Math.floor(hourDiff) > 0 && Math.floor(hourDiff) + (Math.floor(hourDiff) > 1 ? ' hours ' : ' hour ')) || '') + ' ' +
			((Math.round(minDiff - 60 * Math.floor(hourDiff)) + (Math.round(minDiff - 60 * Math.floor(hourDiff)) > 1 ? ' minutes ' : ' minute ')) || '');
		return result;
	}
	return '';
}

export const GetDate = (date?: string | Date) => {
	if (!date) {
		return '';
	}
	const utcDate = new Date(date);
	return utcDate.toISOString().slice(0, 10);
};

export const TimeSince = (date: any) => {
	let seconds = Math.floor((Date.now() - date) / 1000);
	let unit = "second";
	let direction = "ago";
	if (seconds < 30) {
		return direction = "Just now";
	}
	let value = seconds;
	if (seconds >= 31536000) {
		value = Math.floor(seconds / 31536000);
		unit = "year";
	} else if (seconds >= 86400) {
		value = Math.floor(seconds / 86400);
		unit = "day";
	} else if (seconds >= 3600) {
		value = Math.floor(seconds / 3600);
		unit = "hour";
	} else if (seconds >= 60) {
		value = Math.floor(seconds / 60);
		unit = "minute";
	}
	if (value !== 1)
		unit = unit + "s";
	return value + " " + unit + " " + direction;
}

export const IsDateExpired = (date: Date | string) => {
	if (!date) {
		return '';
	}
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return date < today;
}