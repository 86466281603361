import { Component, ReactNode } from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { BlackLogo } from 'Helpers/Images';
import {
  ExpandMoreIcon,
  ExpandLessIcon,
  MenuIcon,
  ChevronLeftIcon,
  MailOutlinedIcon,
  SearchIcon,
  CloseIcon,
} from 'Helpers/Icons';
import { DashboardMainContainer, DrawerHeader } from './styled';
import {
  MuiBox,
  MuiGrid,
  MuiButton,
  MuiIconButton,
  MuiToolbar,
  MuiCssBaseline,
  MuiListItem,
  MuiListItemButton,
  MuiListItemIcon,
  MuiListItemText,
  MuiCollapse,
  MuiList,
  MuiHidden,
  MuiAutocomplete,
  MuiInputField,
  MuiAppBar,
  MuiDrawer,
} from 'Components/MUI';
import { Theme } from '@mui/material/styles';
import { UserModel, PERMISSION, Props, ROLE, States, ActivityListModel } from '../Redux/Models';
import { FindIndex, Map } from 'Helpers/Array';
import { RouteInterface } from 'Routes';
import { SidebarContentWraper } from './styled';
import { Notification, PageContainer, Profile, CustomAlert } from 'Components';
import { Menu, MENUES } from '../Configs/Menus';
import { InviteClientDialog } from 'Components/Dialogs';
import { AuthService, BroadCastService } from 'Services';
import { getDeviceToken, onMsgListner } from '../Firebase';


const drawerWidth = 300;

export default class DashboardLayout extends Component<Props> {
  public state: States = {
    mobileOpen: false,
    isDialogOpen: false,
    toggleMenus: {},
    notificationAanchorEl: null,
    toggleSearchBar: false,
    options: [],
    token: '',
    notification: {
      items: [],
      total: 0,
      show: false,
    }
  };

  public componentDidMount(): void {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.getProfile();
      this.getDeviceId();
      this.getNotificationList();
    }
  }

  public getNotificationList = () => {
    BroadCastService.getNotifications({ limit: 10, page: 1 }).then((resp: any) => {
      if (resp.isSuccess()) {
        const { unseen, items } = resp.data;
        this.setState((prevState: States) => {
          prevState.notification.total = unseen
          prevState.notification.items = items.map((item: any) => ({
            id: item.id,
            title: item.title,
            body: item.description,
            createdAt: item.createdAt,
            link: item.link,
            seen: item.seen,
          }))
          return prevState;
        });
      }
    });
  }

  public getDeviceId = async () => {
    try {
      const { isAuthenticated, } = this.props;
      if (isAuthenticated) {
        const token = await getDeviceToken()
        if (token) {
          this.setState({ token })
          this.props.dispatch(AuthService.addDeviceToken({ token }))
        }
      }
    } catch (err) {
      // console.error(err) 
    }
  }

  public getNewMsg = () => {
    onMsgListner()
      .then(payload => {
        const { notification, data }: any = payload;
        this.setState((prevState: any) => {
          prevState.notification.items = [{ ...notification, ...data, id: data.id, createdAt: new Date(), seen: false }, ...prevState.notification.items]
          prevState.notification.total = prevState.notification.total + 1
          if (data?.['gcm.notification.priority']) prevState.notification.show = true
          return prevState
        })
      })
  }

  public notificationClick = (item: any, func: Function) => {
    const { history } = this.props;
    const { notification } = this.state;
    const index = FindIndex(notification.items, (el: ActivityListModel) => el.id === item.id);
    if (item.link) {
      history.push(item.link);
    }
    if (item.id) {
      BroadCastService.notificationSeen(item.id).then(resp => {
        this.setState((prevState: States) => {
          prevState.notification.items[index].seen = true;
          prevState.notification.total = resp.data?.unseen;
          return prevState;
        })
      })
    }
    func();
  }

  public getProfile = () => {
    this.props.dispatch(AuthService.getMe());
  };

  public toggleDrawer = (): void => {
    const { isDrawerOpen } = this.state;
    this.setState((prevState: States) => {
      prevState.isDrawerOpen = !isDrawerOpen;
      prevState.toggleMenus = {};
      return prevState;
    });
  };

  public handleMenuToggle = (index: string): void => {
    const { toggleMenus } = this.state;
    this.setState((prevState: States) => {
      prevState.toggleMenus[index] = !toggleMenus[index];
      return prevState;
    });
  };

  public handleDrawerToggle = (): void => {
    const { mobileOpen } = this.state;
    this.setState((prevState: States) => {
      prevState.mobileOpen = !mobileOpen;
      return prevState;
    });

    if (mobileOpen === false) {
      document.body.classList.add('sidebarClose');
    } else {
      document.body.classList.remove('sidebarClose');
    }
  };

  public handleDialog = (item: UserModel = {}) => {
    if (item.id) {
      this.props.history.push('/clients');
    }
    this.setState((prevState: States) => {
      prevState.isDialogOpen = !prevState.isDialogOpen;
      return prevState;
    });
  };

  public handleSearch = (e: any) => {
    if (e.target.value) {
      AuthService.cancelRequestIfPending();
      AuthService.getSearchResults({ q: e.target.value }).then((resp) => {
        if (resp.isSuccess()) {
          this.setState((prevState: States) => {
            prevState.options = resp.getData();
            return prevState;
          });
        }
      });
    } else {
      this.setState((prevState: States) => {
        prevState.options = [];
        return prevState;
      });
    }
  };

  public subscribePlan = () => {
    const { history } = this.props;
    history.push('/prices');
  }

  public toggleSearchBarFunc = (): void => {
    const { toggleSearchBar } = this.state;
    this.setState((prevState: States) => (prevState.toggleSearchBar = !toggleSearchBar));
  };

  public closeAlert = () => this.setState((prevState: any) => {
    prevState.notification.show = false
    return prevState
  })

  public onOptionSelect = (event: any, value: any) => {
    const { history } = this.props;
    if (value) {
      if (value.type === 'LOCATION') history.push(`/locations/${value.id}`);
      if (value.type === 'CLIENT') history.push(`/clients/${value.id}`);
      if (value.type === 'CONTRACTOR') history.push(`/contractors/${value.id}`);
      if (value.type === 'EMPLOYEE') history.push(`/employees/${value.id}`);
    }
  }


  public render(): ReactNode {
    const { isDialogOpen, toggleSearchBar, options, notification, mobileOpen } = this.state;
    const { childrens, container, ...restProps }: Props = this.props;
    this.getNewMsg()

    const drawer = (
      <SidebarContentWraper sx={
        [
          (theme: Theme) => ({
            backgroundColor: 'primary.contrastText',
            height: '100%',
            overflow: 'hidden',
          })
        ]} >
        <DrawerHeader
          sx={{
            py: '10px',
            minHeight: '46px',
            backgroundColor: 'primary.contrastText',
            '& .sidebarLogoImg': {
              width: '140px',
              margin: '0 auto'
            },
          }
          }
        >
          <img alt="logo" src={BlackLogo} className="sidebarLogoImg" />
          <MuiIconButton onClick={this.handleDrawerToggle}>
            <ChevronLeftIcon />
          </MuiIconButton>
        </DrawerHeader>
        <MuiList className="sidebarMainListUl"
          sx={{
            backgroundColor: 'primary.contrastText'
          }}
        >
          {Map(MENUES, (menu: Menu, menuIndex) => {
            return (
              <MuiListItem key={menuIndex} disablePadding sx={{ display: 'block' }
              }>
                {
                  menu.childrens?.length && (this.props.user.can(menu.module, PERMISSION.VIEW) || (menu.module.length < 1)) ? (
                    <MuiListItemButton
                      component={'button'}
                      onClick={() => this.handleMenuToggle(menuIndex.toString())
                      }
                      sx={{
                        minHeight: 48,
                        justifyContent: mobileOpen ? 'initial' : 'center',
                        pl: 2,
                        mr: 0,
                        width: '100%',
                        borderRadius: '5px'
                      }}
                    >
                      <MuiListItemIcon
                        sx={
                          [
                            (theme: Theme) => ({
                              minWidth: 0,
                              mr: 2,
                              justifyContent: 'center',
                              [theme.breakpoints.down('lg')]: {
                                mr: 2
                              }
                            })
                          ]
                        }
                      >
                        <menu.icon />
                      </MuiListItemIcon>
                      <MuiListItemText
                        primary={menu.title}
                        sx={
                          [
                            (theme: Theme) => ({
                              [theme.breakpoints.down('lg')]: {
                                opacity: 1
                              }
                            })
                          ]}
                      />
                      {!!this.state.toggleMenus[menuIndex]
                        ? menu.childrens && (
                          <ExpandLessIcon />
                        )
                        : menu.childrens && (
                          <ExpandMoreIcon />
                        )}
                    </MuiListItemButton>
                  ) : (
                    (this.props.user.can(menu.module, PERMISSION.VIEW) || menu.module.length < 1) && (
                      <MuiListItemButton
                        component={NavLink}
                        to={menu.path}
                        activeClassName={menu.active}
                        onClick={() => this.handleMenuToggle(menuIndex.toString())}
                        sx={{
                          minHeight: 48,
                          pl: 2,
                          mr: 0,
                          width: '100%',
                          borderRadius: '5px',
                          '& .MuiTypography-root': {
                            whiteSpace: 'initial'
                          }
                        }}
                      >
                        <MuiListItemIcon
                          sx={
                            [
                              (theme: Theme) => ({
                                minWidth: 0,
                                mr: 2,
                                justifyContent: 'center',
                              })
                            ]
                          }
                        >
                          <menu.icon />
                        </MuiListItemIcon>
                        <MuiListItemText primary={menu.title} />
                        {!!this.state.toggleMenus[menuIndex]
                          ? menu.childrens && (
                            <ExpandLessIcon />
                          )
                          : menu.childrens && (
                            <ExpandMoreIcon />
                          )}
                      </MuiListItemButton>
                    )
                  )}
                {
                  menu.childrens && (
                    <MuiCollapse in={!!this.state.toggleMenus[menuIndex]} timeout="auto" unmountOnExit >
                      {
                        Map(
                          menu.childrens,
                          (submenu: Menu, submenuIndex) =>
                            this.props.user.can(submenu.module, PERMISSION.VIEW) && (
                              <MuiList key={submenuIndex} disablePadding sx={{ my: '5px' }
                              }>
                                <MuiListItemButton
                                  component={NavLink}
                                  to={submenu.path}
                                  activeClassName={submenu.active}
                                  sx={
                                    [
                                      (theme: Theme) => ({
                                        pl: 3
                                      })
                                    ]}
                                >
                                  <MuiListItemIcon sx={{ minWidth: 0, mr: 0 }}>
                                    <submenu.icon sx={{ mr: 2 }} />
                                  </MuiListItemIcon>
                                  <MuiListItemText primary={submenu.title} />
                                </MuiListItemButton>
                              </MuiList>
                            )
                        )}
                    </MuiCollapse>
                  )}
              </MuiListItem>
            );
          })}
        </MuiList>
      </SidebarContentWraper>
    );


    return (
      <DashboardMainContainer>
        <MuiBox>
          <MuiCssBaseline />
          <MuiAppBar position="fixed" sx={[
            (theme: Theme) => ({
              width: this.props.user.subscribed === 0 ? '100% !important' : '100%',
              ml: this.props.user.subscribed === 0 ? "0 !important" : 0,
              backgroundColor: 'primary.dark',
              transition: '0.4s',
              boxShadow: 'none',
              [theme.breakpoints.up('lg')]: {
                width: !mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
                ml: !mobileOpen ? `${drawerWidth}px` : 0
              }
            })
          ]}
          >
            <MuiToolbar
              sx={[
                (theme: Theme) => ({
                  minHeight: '46px !important',
                  backgroundColor: 'primary.contrastText',
                  py: '10px',
                  [theme.breakpoints.down('lg')]: {
                    width: '100%',
                    marginLeft: 0
                  }
                })
              ]}
            >
              {this.props.user.subscribed === 0 && <DrawerHeader
                sx={[
                  (theme: Theme) => ({
                    minHeight: '50px !important',
                    '& .sidebarLogoImg': {
                      width: '140px',
                      [theme.breakpoints.down('sm')]: {
                        width: '120px',
                      },
                    },
                  })
                ]
                }
              >
                <img alt="logo" src={BlackLogo} className="sidebarLogoImg" />
              </DrawerHeader>}

              {this.props.user.subscribed !== 0 && <MuiIconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                sx={[
                  (theme: Theme) => ({
                    backgroundColor: 'primary.main',
                    color: 'primary.contrastText',
                    ml: 0,
                    mr: 2,
                    width: 42,
                    height: 42,
                    transition: '0.4s',
                    display: {
                      lg: !mobileOpen ? 'none' : 'block',
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: 30,
                      height: 30,
                      mr: 1,
                    },
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                      color: 'primary.contrastText',
                      transition: '0.4s',
                    }
                  })
                ]}
              >
                <MenuIcon />
              </MuiIconButton>}
              <MuiGrid container spacing={0} alignItems="center" className='autocompleteGroupBox'>
                <MuiGrid item xs={2} sm={4} md={5} lg={5} sx={{
                  '& .MuiFormControl-root': {
                    mb: 0
                  }
                }}>
                  {this.props.user.subscribed !== 0 &&
                    <>
                      <MuiHidden smDown={true}>
                        <MuiAutocomplete
                          groupBy={(option: any) => option.type}
                          onInputChange={this.handleSearch}
                          disablePortal
                          options={options}
                          clearOnBlur={false}
                          onChange={(event: any, value: any) => this.onOptionSelect(event, value)}
                          getOptionLabel={(option: any) => {
                            if (option.type === 'LOCATION') return option.name;
                            if ((option.type === 'CLIENT') || (option.type === 'CONTRACTOR') || (option.type === 'EMPLOYEE')) return (option.firstName || '') + " " + (option.lastName || '');
                          }}
                          renderInput={(props) => <MuiInputField {...props} placeholder={'Search'} />}
                        />
                      </MuiHidden>
                      <MuiHidden smUp={true}>
                        <MuiIconButton onClick={this.toggleSearchBarFunc}>{toggleSearchBar ? <CloseIcon /> : <SearchIcon />}</MuiIconButton>
                        {toggleSearchBar && (
                          <MuiBox
                            sx={[
                              (theme: Theme) => ({
                                [theme.breakpoints.down('sm')]: {
                                  position: 'absolute',
                                  top: 50,
                                  width: 'calc(100% - 40px)',
                                  left: 20,
                                  right: 20,
                                  maxWidth: 400,
                                  '& .MuiInputBase-root': {
                                    background: theme.palette.primary.contrastText,
                                    boxShadow: '0 0 15px #ebebeb',
                                    position: 'relative',
                                    '&::before': {
                                      content: "''",
                                      position: 'absolute',
                                      top: '-9.5px',
                                      left: '38px',
                                      zIndex: 1,
                                      borderBottom: '10px solid #00000059',
                                      borderLeft: '10px solid transparent',
                                      borderRight: '10px solid transparent'
                                    },
                                    '&::after': {
                                      content: "''",
                                      position: 'absolute',
                                      top: '-8px',
                                      left: '38px',
                                      zIndex: 1,
                                      borderBottom: '10px solid #fff',
                                      borderLeft: '10px solid transparent',
                                      borderRight: '10px solid transparent'
                                    }
                                  }
                                }
                              })
                            ]}
                          >
                            <MuiAutocomplete
                              groupBy={(option: any) => option.type}
                              onInputChange={this.handleSearch}
                              disablePortal
                              options={options}
                              clearOnBlur={false}
                              onChange={(event: any, value: any) => this.onOptionSelect(event, value)}
                              getOptionLabel={(option: any) => {
                                if (option.type === 'LOCATION') return (option.name || '');
                                if ((option.type === 'CLIENT') || (option.type === 'CONTRACTOR') || (option.type === 'EMPLOYEE')) return (option.firstName || '') + " " + (option.lastName || '');
                              }}
                              renderInput={(props) => <MuiInputField {...props} placeholder={'Search'} />}
                            />
                          </MuiBox>
                        )}
                      </MuiHidden>
                    </>
                  }
                </MuiGrid>
                <MuiGrid item xs={10} sm={8} md={7} lg={7} sx={[
                  (theme: Theme) => ({
                    ...theme.components?.MuiGrid?.defaultProps?.sx,
                    [theme.breakpoints.down('md')]: {
                      pr: 0
                    },
                    [theme.breakpoints.down('sm')]: {
                      width: '100%'
                    }
                  })
                ]}
                >
                  <MuiBox display="flex" justifyContent="flex-end" alignItems="center">
                    {this.props.user.hasRole(ROLE.SUPER_ADMIN) && this.props.history.location.pathname === '/dashboard' && (
                      <MuiButton
                        startIcon={<MailOutlinedIcon />}
                        onClick={this.handleDialog}
                        sx={[
                          (theme) => ({
                            ...theme.components?.MuiButton?.defaultProps?.sx,
                            [theme.breakpoints.down('sm')]: {
                              fontSize: 14,
                              px: 1.5,
                              '& svg': {
                                fontSize: '16px !important'
                              }
                            },
                            [theme.breakpoints.down(400)]: {
                              fontSize: 13,
                              '& .MuiButton-startIcon': {
                                marginRight: '5px',
                              }
                            }
                          }),
                          {
                            py: 1,
                            fontSize: 16
                          }
                        ]}
                      >
                        Invite Client
                      </MuiButton>
                    )}
                    {this.props.user.hasRole(ROLE.CLIENT) && this.props.history.location.pathname !== '/plans' && (this.props.user.subscribed === 0 || this.props.user.subscribed === false) && (
                      <MuiButton
                        onClick={() => this.props.history.push('/plans')}
                        sx={[
                          (theme) => ({
                            ...theme.components?.MuiButton?.defaultProps?.sx,
                            [theme.breakpoints.down('sm')]: {
                              fontSize: 14,
                              px: 1.5,
                              '& svg': {
                                fontSize: '16px !important'
                              }
                            },
                            [theme.breakpoints.down(400)]: {
                              fontSize: 13,
                              '& .MuiButton-startIcon': {
                                marginRight: '5px',
                              }
                            }
                          }),
                          {
                            py: 1,
                            fontSize: 16,
                            mr: this.props.user.subscribed === 0 ? 2 : 0
                          }
                        ]}
                      >
                        Subscribe
                      </MuiButton>
                    )}
                    {this.props.user.hasRole(ROLE.CLIENT) && this.props.history.location.pathname !== '/upgrade' && this.props.user.subscribed !== 0 && (
                      <MuiButton
                        onClick={() => this.props.history.push('/upgrade')}
                        sx={[
                          (theme) => ({
                            ...theme.components?.MuiButton?.defaultProps?.sx,
                            [theme.breakpoints.down('sm')]: {
                              fontSize: 14,
                              px: 1.5,
                              '& svg': {
                                fontSize: '16px !important'
                              }
                            }
                          }),
                          {
                            py: 1,
                            fontSize: 16
                          }
                        ]}
                      >
                        Upgrade
                      </MuiButton>
                    )}
                    {this.props.user.subscribed !== 0 &&
                      <Notification notificationClick={this.notificationClick} {...restProps} notification={notification} />}
                    <Profile {...restProps} />
                  </MuiBox>
                </MuiGrid>
              </MuiGrid>
            </MuiToolbar>
          </MuiAppBar>

          {this.props.user.subscribed !== 0 && <MuiBox component="nav" sx={{
            width: {
              lg: !mobileOpen ? drawerWidth : 0,
            },
            flexShrink: {
              lg: 0
            },
          }}>
            <MuiDrawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={this.handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={[
                (theme: Theme) => ({
                  display: {
                    md: 'block',
                    lg: 'none'
                  },
                  '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    backgroundColor: 'primary.dark',
                    border: 'none',
                    transition: '0.4s',
                    boxShadow: '0 0 15px #00000012',
                    width: {
                      md: drawerWidth,
                      lg: !mobileOpen ? drawerWidth : 0,
                    }
                  },
                })
              ]}>
              {drawer}
            </MuiDrawer>
            <MuiDrawer variant="permanent" open sx={[
              (theme: Theme) => ({
                display: 'block',
                [theme.breakpoints.down("md")]: {
                  display: 'none'
                },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  backgroundColor: 'primary.dark',
                  border: 'none',
                  transition: '0.4s',
                  boxShadow: '0 0 15px #00000012',
                  width: {
                    md: !mobileOpen ? 0 : drawerWidth,
                    lg: !mobileOpen ? drawerWidth : 0,
                  }
                },
              })
            ]}>
              {drawer}
            </MuiDrawer>
          </MuiBox>}

          <MuiBox component="main" className="dashboardContentWrapper" sx={[
            (theme: Theme) => ({
              flexGrow: 1,
              transition: '0.4s',
              p: 3,
              marginTop: this.props.user.subscribed === 0 ? '50px' : '40px',
              minHeight: (this.props.user.subscribed === 0 && this.props.user.hasRole(ROLE.CLIENT)) ? '93.5vh !important' : '100vh',
              width: this.props.user.subscribed === 0 ? '100% !important' : '100%',
              ml: this.props.user.subscribed === 0 ? "0 !important" : 0,
              [theme.breakpoints.up("lg")]: {
                marginTop: '50px',
                width: !mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%',
                ml: !mobileOpen ? `${drawerWidth}px` : 0
              },
              [theme.breakpoints.up("xl")]: {
                marginTop: '65px',
              },
            })
          ]}>
            <MuiBox>
              {this.props.user.subscribed !== 0 && <CustomAlert notification={notification} onClose={this.closeAlert} />}
              <Switch>
                {Map(childrens, (route: RouteInterface, i) => {
                  return (
                    <Route
                      key={i}
                      exact={route.exact}
                      path={route.path}
                      render={(rProps: any) =>
                        !route.redirect ? (
                          <PageContainer title={route.name} {...restProps}>
                            <route.component {...restProps} {...rProps} childrens={route.childrens} />
                          </PageContainer>
                        ) : (
                          <Redirect to={route.redirect || '/'} />
                        )
                      }
                    />
                  );
                })}
              </Switch>
            </MuiBox>
          </MuiBox>
          {isDialogOpen && <InviteClientDialog open={isDialogOpen} onClose={this.handleDialog} />}
        </MuiBox>
      </DashboardMainContainer>
    );
  }
}
