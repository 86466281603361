import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, LocationModel, FormModel } from '../Redux/Models';
import { UploadModel } from 'Redux/Models/Base.Models';

export default class LocationService extends BaseService {
	public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<LocationModel>> {
		let url: string = '/locations';
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		if (options?.source) {
			LocationService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new ResponseList<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getAll(options: Options = {}): Promise<Response<LocationModel[]>> {
		let url: string = '/locations';
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(url, { params: options.params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<LocationModel[]>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static deleteFile(id: number, uploadId: number): Promise<Response<UploadModel>> {
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete(`/locations/${id}/uploads/${uploadId}`, {
				cancelToken: LocationService.source?.token
			})
				.then((res) => {
					let data = new Response<UploadModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static uploadFiles(id: number, params: Object = {}): Promise<Response<Array<UploadModel>>> {
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post(`/locations/${id}/uploads`, params, { cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<Array<UploadModel>>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getById(id: number, params: Object = {}, options: Options = {}): Promise<Response<LocationModel>> {
		let url: string = '/locations/' + id;
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(url, { params: params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getByFormsByLocation(id: number, params: Object = {}, options: Options = {}): Promise<Response<Array<FormModel>>> {
		let url: string = '/locations/' + id + '/forms';
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(url, { params: params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<Array<FormModel>>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static create(params: Object = {}, options: Options = {}): Promise<Response<LocationModel>> {
		let url: string = '/locations';
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post(url, params, { cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static update(id: number, params: Object = {}, options: Options = {}): Promise<Response<LocationModel>> {
		let url: string = '/locations/' + id;
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch(url, params, { cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static delete(id: number, options: Options = {}): Promise<Response<LocationModel>> {
		let url: string = '/locations/' + id;
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete(url, { params: options?.params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateStatus(id: number, options: Options = {}): Promise<Response<LocationModel>> {
		let url: string = '/locations/' + id;
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put(url, { params: options?.params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getContractorLocations(contractorId: number, params: Object = {}): Promise<Response<LocationModel>> {
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(`/contractors/${contractorId}/locations/`, {
				params,
				cancelToken: LocationService.source?.token
			})
				.then((res) => {
					const data = new Response<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static sendQrCode(id: number, params: Object = {}): Promise<Response<LocationModel>> {
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post(`/locations/${id}/send`, params, { cancelToken: LocationService.source?.token })
				.then((res) => {
					const data = new Response<LocationModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static uploadGuideLine(id: number, params: Object = {}, options: Options = {}): Promise<Response<Array<UploadModel>>> {
		let url: string = `/locations/${id}/guidelines`;
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post(url, params, { cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<Array<UploadModel>>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getGuidelinesByLocation(id: number, params: Object = {}, options: Options = {}): Promise<Response<Array<UploadModel>>> {
		let url: string = `/locations/${id}/guidelines`;
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<Array<UploadModel>>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static deleteGuideline(locationId: number, id: number, params: Object = {}, options: Options = {}): Promise<Response<Array<UploadModel>>> {
		let url: string = `/locations/${locationId}/guidelines/${id}`;
		if (options?.root) {
			url = options.root + url;
		}
		LocationService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete(url, { params, cancelToken: LocationService.source?.token })
				.then((res) => {
					let data = new Response<Array<UploadModel>>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
