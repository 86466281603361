import { INDUCTION_STATUS } from 'Configs/Constants';
import { BaseStateModel, FormModel, FORMTYPE, LocationModel, FIELD } from '../Models';

export class FormState extends BaseStateModel {
	public loading: boolean = false;
	public formErr?: { [key: string]: string | undefined };
	public form: FormModel = {
		title: '',
		type: FORMTYPE.CHECK_IN,
		fields: [
			{
				type: FIELD.TEXT,
				value: '',
				required: false,
				title: '',
				defaultValue: '',
				number: '',
				options: [
					{ value: '', selected: false },
					{ value: '', selected: false }
				]
			}
		]
	};
}

export class LocationFormState extends BaseStateModel {
	public location: LocationModel = {};
}

export class GeneralFormState extends BaseStateModel {
	public check: LocationModel = {
		entryCondition: false
	};

	public location: LocationModel = {
		generalName: '',
		generalAddress: '',
		generalDescription: ''
	};
}

export class NotificationFormState extends BaseStateModel {
	public location: LocationModel = {
		notificationName: '',
		notificationPhone: '',
		notificationEmail: ''
	};
}

class FormResponseModel {
	checkIn?: Array<[]>;
	checkOut?: Array<[]>;
	siteInduction?: Array<[]>;
}

export class FormResponseState extends BaseStateModel {
	public tab: number = 0;
	public inductionStatus: string = INDUCTION_STATUS[0].value;
	public form: FormResponseModel = {
		checkIn: [],
		checkOut: [],
		siteInduction: [],
	}
}