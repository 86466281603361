import { BaseStateModel, UserModel } from "../Models";

export class LoginState extends BaseStateModel {
  public loading: boolean = false;
  public loginModel: UserModel = {
    email: "",
    password: "",
    rememberMe: "",
  };
  public isCheckOTP: boolean = false;
}

export class RequestPasswordState extends BaseStateModel {
  public loading: boolean = false;
  public requestPasswordModel: UserModel = {
    email: "",
  };
  public isCheckOTP: boolean = false;
}

export class ResetPasswordState extends BaseStateModel {
  public loading: boolean = false;
  public resetPasswordModel: UserModel = {
    password: "",
    confirmPassword: "",
  };
  public isCheckOTP: boolean = false;
}

export class RegisterState extends BaseStateModel {
  public loading: boolean = false;
  public step: number = 1;
  public form: UserModel & { companyName: string } = {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
    name: "",
    address: "",
    abn: ''
  };
}
