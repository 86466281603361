import { Component, MouseEvent, ReactNode } from 'react';
import { MuiButton, MuiMenu, MuiMenuItem, MuiAvatar, MuiHidden, MuiTypography } from 'Components/MUI';
import { Theme } from '@mui/material';
import { KeyboardArrowDownIcon } from 'Helpers/Icons';
import { Props, Response, ROLE, States } from 'Redux/Models';
import { Link } from 'react-router-dom';
import { GetImage } from 'Helpers/String';
import { AuthService } from 'Services';

export default class Profile extends Component<Props, States> {
	public state: States = {
		anchorEl: null
	};
	public handleProfileDropdownOpen = (event: MouseEvent<HTMLButtonElement>) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	public handleProfileDropdownClose = () => {
		this.setState({ anchorEl: null });
	};

	public handleLogout = (): void => {
		const { history, dispatch, deviceToken } = this.props;
		dispatch(AuthService.logOut({ token: deviceToken }))
			.then((resp: Response<any>) => {
				if (resp.isSuccess()) history.push('/login')
			})
		// .catch((err: any) => console.log(err))
	};

	public render(): ReactNode {
		const { user } = this.props;
		const { anchorEl } = this.state;
		return (
			<>
				<MuiButton
					loading={false}
					id="profileBtn"
					aria-haspopup="true"
					onClick={this.handleProfileDropdownOpen}
					fullWidth={false}
					variant="outlined"
					className="profileDrodownBtn"
					sx={[
						(theme) => ({
							...theme.components?.MuiButton?.defaultProps?.sx,
							[theme.breakpoints.down('sm')]: {
								fontSize: 14,
								padding: '0 !important',
								border: 'none !important',
								background: 'transparent !important',
								minWidth: 'auto',
								borderRadius: '50%',
								'&.MuiButtonBase-root': {
									border: 'none !important',
									background: 'transparent !important',
									'&:hover': {
										border: 'none !important',
										background: 'transparent !important'
									}
								}
							}
						}),
						{
							display: 'flex',
							alignItems: 'center',
							borderRadius: '8px',
							color: '#000 !important',
							bgcolor: '#f9f9f9 !important',
							'&:hover': {
								border: '1px solid #000 !important'
							}
						}
					]}
				>
					<MuiAvatar
						sx={[
							(theme: Theme) => ({
								mx: 1,
								width: 35,
								height: 35,
								border: `2px solid ${theme.palette.primary.dark}`,
								[theme.breakpoints.up('xl')]: {
									width: 45,
									height: 45
								},
								[theme.breakpoints.down('sm')]: {
									mx: 0
								}
							})
						]}
						src={user.upload ? GetImage(user.upload?.path) : ''}
					/>
					<MuiHidden smDown={true}>
						<MuiTypography component="span" variant="h6" sx={[
							(theme: Theme) => ({
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								display: '-webkit-box',
								WebkitBoxOrient: 'vertical',
								WebkitLineClamp: 1,
								maxWidth: 75,
								textTransform: 'uppercase',
								textAlign: 'left',
							})
						]}>
							{user?.firstName?.substring(0, 1) + '' + user?.lastName?.substring(0, 1)}
						</MuiTypography>
					</MuiHidden>

					<MuiHidden smDown={true}>
						<KeyboardArrowDownIcon />
					</MuiHidden>
				</MuiButton>
				<MuiMenu
					id="profileMenu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={this.handleProfileDropdownClose}
					MenuListProps={{
						'aria-labelledby': 'profileBtn'
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
					sx={[
						(theme: Theme) => ({
							'& .MuiMenuItem-root': {
								color: theme.palette.info.main,
								[theme.breakpoints.down('sm')]: {
									minHeight: 'auto'
								}
							},
							'& .MuiPaper-root': {
								[theme.breakpoints.up(400)]: {
									position: 'relative',
									overflow: 'visible',
									top: '60px !important',
									border: '1px solid #dfdfdf',
									maxWidth: 300,
									'&::before, &::after': {
										content: '""',
										position: 'absolute',
										top: '-10px',
										right: '10px',
										borderBottom: '10px solid #dfdfdf',
										borderRight: '10px solid transparent',
										borderLeft: '10px solid transparent',
									},
									'&::after': {
										top: '-9px',
										right: '10px',
										borderBottom: '10px solid #fff',
									},
								}
							},
						})
					]}
				>
					<MuiMenuItem onClick={this.handleProfileDropdownClose} component={Link} to="/profile">
						Profile
					</MuiMenuItem>
					{user.hasRole(ROLE.CLIENT) && this.props.user.subscribed !== 0 && (
						<MuiMenuItem onClick={this.handleProfileDropdownClose} component={Link} to="/subscription">
							My Subscription
						</MuiMenuItem>
					)}
					<MuiMenuItem onClick={this.handleLogout}>Logout</MuiMenuItem>
				</MuiMenu>
			</>
		);
	}
}
