import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, ServiceModel } from '../Redux/Models';

export default class ServicesService extends BaseService {
	public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<ServiceModel>> {
		let url: string = '/services';
		if (options?.root) {
			url = options.root + url;
		}
		ServicesService.initCancelToken();
		if (options?.source) {
			ServicesService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: ServicesService.source?.token })
				.then((res) => {
					let data = new ResponseList<ServiceModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getDetailsById(id: number, params: Object = {}): Promise<Response<ServiceModel>> {
		ServicesService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/services/' + id, { params, cancelToken: ServicesService.source?.token })
				.then((res) => {
					let data = new Response<ServiceModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static create(params: Object = {}): Promise<Response<ServiceModel>> {
		ServicesService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/services', params, { cancelToken: ServicesService.source?.token })
				.then((res) => {
					let data = new Response<ServiceModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static update(id: number, params: Object = {}): Promise<Response<ServiceModel>> {
		ServicesService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch('/services/' + id, { ...params, cancelToken: ServicesService.source?.token })
				.then((res) => {
					let data = new Response<ServiceModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static delete(id: number): Promise<Response<ServiceModel>> {
		ServicesService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete('/services/' + id, { cancelToken: ServicesService.source?.token })
				.then((res) => {
					let data = new Response<ServiceModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static toggleStatus(id: number): Promise<Response<ServiceModel>> {
		ServicesService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch('/services/' + id + '/status', { cancelToken: ServicesService.source?.token })
				.then((res) => {
					let data = new Response<ServiceModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getServices(params = {}): Promise<Response<ServiceModel[]>> {
		ServicesService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/services', {
				params: { status: 'ACTIVE', ...params },
				cancelToken: ServicesService.source?.token
			})
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
