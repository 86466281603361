import { styled as MuiStyle, Theme } from '@mui/material';
import { ThemeProps } from 'styled-components';
import { MuiCard } from 'Components/MUI';

export const Container = MuiStyle(MuiCard)(({ theme }: ThemeProps<Theme>) => ({
	background: theme.palette.primary.contrastText,
	borderRadius: 15,
	padding: 20,
	boxShadow: theme.shadows[2]
}));
