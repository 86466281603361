import { GetDateTime } from 'Helpers/String';
import { BaseStateModel, DataTableModel, GENDER, UserModel, LocationModel, InvoiceModel, ActivityListModel } from 'Redux/Models';

export class ClientProfileState extends BaseStateModel {
	public loading: boolean = false;
	public statusColor: string = '';
	public tab: number = 0;
	public client: UserModel = {
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		gender: GENDER.MALE,
		image: '',
		role: ''
	};
	public changePassword: UserModel = {
		currentPassword: '',
		password: '',
		confirmPassword: ''
	};
}

export class ClientListState extends BaseStateModel {
	public isDialogOpen: boolean = false;
	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'clients?with[]=invoices',
		headers: [
			{
				key: 'firstName',
				label: 'Management Name',
				isSort: true,
				render: (item: UserModel) => `${item.firstName} ${item.lastName}`
			},
			{ key: 'email', label: 'Email', isSort: true },
			{ key: 'phone', label: 'Phone', isSort: true, style: { minWidth: '200px' } },
		],
		filters: [
			{
				name: 'Status',
				key: 'status',
				items: [
					{ label: 'Active', value: 'ACTIVE' },
					{ label: 'Inactive', value: 'INACTIVE' },
					{ label: 'Invited', value: 'DRAFT' }
				],
				multiple: false
			},
		]
	};
}

export class InviteClientState extends BaseStateModel {
	public loading: boolean = false;
	public client: UserModel & { address: string, abn?: string } = {
		companyName: '',
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		address: '',
		abn: ''
	};
}

export class ClientDetailState extends BaseStateModel {
	public loading: boolean = false;
	public tab: number = 0;
	public formErr?: { file?: string };
	public invoices: Array<InvoiceModel> = [];
	public activity?: Array<ActivityListModel> = [];
	public client: UserModel = {
		firstName: '',
		lastName: '',
		phone: '',
		role: '',
		email: '',
		gender: GENDER.MALE,
		image: '',
		createdAt: '',
		uploads: [],
		subscriptions: [],
	};

	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'locations',
		headers: [
			{ key: 'name', label: 'Building Name/Reference', isSort: true },
			{ key: 'address', label: 'Address', isSort: true, style: { minWidth: '200px' } },
			{ key: 'contactName', label: 'Site Contact', isSort: true },
			{ key: 'ownerName', label: 'Management Name', isSort: true },
			{
				key: 'updatedAt',
				label: 'Last Updated',
				isSort: true,
				render: (item: LocationModel) => GetDateTime(item.updatedAt)
			}
		]
	};
}

