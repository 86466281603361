import { ChangeEvent, DragEvent, useState, useRef } from 'react';
import { MuiBox, MuiTypography, MuiButton, MuiList, MuiListItem, MuiListItemText, MuiListItemAvatar, MuiAvatar, MuiIconButton, MuiFormHelperText } from 'Components/MUI';
import { InsertDriveFileIcon, DeleteIcon } from 'Helpers/Icons';
import { Theme } from '@mui/material/styles';
import { UploadModel } from 'Redux/Models';
import { Alert } from 'Helpers/Alert';
import { DownloadLink } from 'Components';
import { Map } from 'Helpers/Array';

interface Props {
	onChange: (files: Array<File>) => void;
	onDelete: (file: UploadModel) => void;
	files: Array<UploadModel>;
	allowedTypes: Array<string>;
	children?: JSX.Element;
	multiple?: boolean;
	loading?: boolean;
}

export default function DragDropFileUploader(props: Props) {
	const { onChange, files, onDelete, allowedTypes, children, multiple = false, loading = false } = props
	const [state, setState] = useState({ message: '', hasError: false })
	const hiddenFileInput: any = useRef(null)

	const handleDrop = (e: DragEvent<HTMLDivElement>): void => {
		e.preventDefault();
		const fileList = e.dataTransfer.files;
		validateAndSubmit(fileList)
	};

	const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		e.stopPropagation();
		validateAndSubmit(e.target.files)
	};

	const validateAndSubmit = (fileList: FileList | null) => {
		setState({ hasError: false, message: '' });
		if (fileList?.length) {
			const inputFiles = fileList
			let msg = ''
			for (let i = 0; i < inputFiles.length; i++) {
				const item = inputFiles[i]
				if (!allowedTypes.includes(item.type)) {
					msg = `Allowed file type(s) are ${allowedTypes.map(typ => typ.substring(typ.indexOf('/') + 1))}`
					break
				}
				else if (item.size > 2 * 10 ** 6) {
					msg = 'Maximum file size is 2MB'
					break
				}
				else if (files.find(f => f.originalName === item.name)) {
					msg = 'The file(s) is/are already taken'
					break
				}
			}
			if (msg) {
				setState({ hasError: true, message: msg })
				return
			}
			checkAndTigger(fileList)
		}
	}

	const checkAndTigger = (files: FileList) => {
		setState({ hasError: false, message: '' });
		if (files.length) {
			const data: Array<File> = [];
			for (let i = 0; i < files.length; i++) {
				const item = files[i];
				data.push(item);
			}
			onChange(data);
		}
	};

	const prevent = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleDelete = (file: UploadModel) => {
		Alert.confirm('Are you sure?', 'You want to delete the file').then((resp) => {
			if (resp) {
				setState({ hasError: false, message: '' });
				onDelete(file);
			}
		});
	};

	const resetFileInput = (e: any) => {
		e.target.value = null
		e.target.files = null
	}

	return (
		<>
			<form>
				<MuiBox
					onDrop={handleDrop}
					onDragOver={prevent}
					onDragEnter={prevent}
					onDragLeave={prevent}
					onClick={() => hiddenFileInput?.current?.click()}
					py={5}
					sx={[
						(theme: Theme) => ({
							p: 3,
							width: '100%',
							display: 'flex',
							boxShadow: 'none',
							cursor: 'pointer',
							borderRadius: '5px',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'center',
							border: `1px solid ${theme.palette.grey[800]}`
						})
					]}
				>
					<MuiTypography variant="h5" color="secondary.dark">
						{' '}
						Drag and drop file{' '}
					</MuiTypography>
					<MuiTypography variant="subtitle2" display="block" my={1}>
						Or
					</MuiTypography>
					<MuiButton loading={loading} variant="contained">
						Browse
					</MuiButton>
					<MuiFormHelperText error={state.hasError}>{state.message || ' '}</MuiFormHelperText>
				</MuiBox>
				<input name="files" multiple={multiple} type="file" onClick={resetFileInput} ref={hiddenFileInput} style={{ display: 'none' }} onChangeCapture={handleFileSelect} />
				{children && children}
				<MuiList sx={{ width: '100%' }}>
					{
						Map(files, ((file, index) => (
							<MuiListItem
								key={file.id || index}
								alignItems="flex-start"
								secondaryAction={
									<MuiIconButton onClick={() => handleDelete(file)} edge="end" aria-label="delete">
										<DeleteIcon />
									</MuiIconButton>
								}
							>
								<MuiListItemAvatar>
									<MuiAvatar sx={{ backgroundColor: 'primary.main' }}>
										{' '}
										<InsertDriveFileIcon />{' '}
									</MuiAvatar>
								</MuiListItemAvatar>
								<MuiListItemText
									primary={file.id ? <DownloadLink link={file.path!}>{file.originalName!}</DownloadLink> : <MuiTypography variant="h6">{file.name}</MuiTypography>}
									secondary={
										<MuiTypography variant="caption">{file.size > 10 ** 6 ? `${Math.round((file.size / 10 ** 6) * 100) / 100} MB` : `${Math.round((file.size / 10 ** 3) * 100) / 100} KB`}</MuiTypography>
									}
								/>
							</MuiListItem>
						)))
					}
				</MuiList>
			</form>
		</>
	);
}
