import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ContractortModel } from '../Redux/Models';

export default class ContractorService extends BaseService {

	public static getList(params: Object = {}, options: Options = {}): Promise<Response<ContractortModel[]>> {
		let url: string = '/contractors';
		if (options?.root) {
			url = options.root + url;
		}
		ContractorService.initCancelToken();
		if (options?.source) {
			ContractorService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: ContractorService.source?.token })
				.then((res) => {
					let data = new Response<ContractortModel[]>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static invite(params: Object = {}): Promise<Response<ContractortModel>> {
		ContractorService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/contractors', params, { cancelToken: ContractorService.source?.token })
				.then((res) => {
					let data = new Response<ContractortModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getContractorById(id: number, params: Object = {}): Promise<Response<ContractortModel>> {
		ContractorService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/contractors/' + id, { params, cancelToken: ContractorService.source?.token })
				.then((res) => {
					let data = new Response<ContractortModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static delete(id: number, params: Object = {}): Promise<Response<ContractortModel>> {
		ContractorService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete('/contractors/' + id, { params, cancelToken: ContractorService.source?.token })
				.then((res) => {
					let data = new Response<ContractortModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static verify(token: string, params: Object = {}): Promise<Response<ContractortModel>> {
		ContractorService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/contractors/invited/' + token, {
				params,
				cancelToken: ContractorService.source?.token
			})
				.then((res) => {
					let data = new Response<ContractortModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateStatus(id: number, params: Object = {}): Promise<Response<ContractortModel>> {
		ContractorService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/contractors/' + id, params, { cancelToken: ContractorService.source?.token })
				.then((res) => {
					let data = new Response<ContractortModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static update(id: number, params: Object = {}): Promise<Response<ContractortModel>> {
		ContractorService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/contractors/' + id, params, { cancelToken: ContractorService.source?.token })
				.then((res) => {
					let data = new Response<ContractortModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
