import BaseService from './BaseService';
import { Http } from '../Http';
import { ModuleModel, PermissionModel, Response, RoleModel } from 'Redux/Models';

export default class RoleService extends BaseService {
	public static getRoles(params = {}): Promise<Response<RoleModel[]>> {
		RoleService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/roles', { params, cancelToken: RoleService.source?.token })
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static internalOnly(params = {}): Promise<Response<RoleModel[]>> {
		RoleService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/roles/internal', { params, cancelToken: RoleService.source?.token })
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static userList(params = {}): Promise<Response<RoleModel[]>> {
		RoleService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get(`/roles/users`, { params, cancelToken: RoleService.source?.token })
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getPermissions(params = {}): Promise<Response<Array<PermissionModel>>> {
		RoleService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/permissions', { params, cancelToken: RoleService.source?.token })
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getModules(params = {}): Promise<Response<Array<ModuleModel>>> {
		RoleService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/modules', { params, cancelToken: RoleService.source?.token })
				.then((res) => {
					if (res?.data.status === 200) {
						return resolve(new Response(res?.data));
					}
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static roleAdd(params: Object): Promise<Response<RoleModel>> {
		return new Promise((resolve, reject) => {
			Http.post('/roles', params)
				.then((res) => {
					return resolve(new Response(res?.data));
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static update(id: number, params: Object = {}): Promise<Response<RoleModel>> {
		return new Promise((resolve, reject) => {
			Http.patch('/roles/' + id, params, { cancelToken: RoleService.source?.token })
				.then((res) => {
					return resolve(new Response(res?.data));
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getRoleById(id: number, params = {}): Promise<Response<RoleModel>> {
		RoleService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/roles/' + id, { params: params, cancelToken: RoleService.source?.token })
				.then((res) => {
					let data = new Response<RoleModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateStatus(id: number, params: Object = {}): Promise<Response<RoleModel>> {
		RoleService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/roles/' + id, params, { cancelToken: RoleService.source?.token })
				.then((res) => {
					let data = new Response<RoleModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
