import { MuiBox, MuiButton, MuiGrid, MuiTypography } from 'Components/MUI';
import { ArrowBackIcon } from 'Helpers/Icons';
import { Component, ReactNode } from 'react';
import { Props } from 'Redux/Models';
import { Container } from './styled';

export default class PageContainer extends Component<Props> {
	public render(): ReactNode {
		const { children, title, history, location } = this.props;

		const dashboardPath = location.pathname === '/dashboard';

		return (
			<MuiBox>
				<MuiGrid container py={2} sx={{ display: 'flex', alignItems: 'center' }}>
					{location.pathname !== '/dashboard' && (
						<MuiGrid item sm={6} xs={7}>
							{!!title && (
								<MuiTypography variant="h3" color="secondary.dark" display="flex" alignItems="center" fontWeight="700" >
									{title}
								</MuiTypography>
							)}
						</MuiGrid>
					)}
					<MuiGrid item sm={6} xs={5} textAlign="right">
						{location.pathname !== '/dashboard' && (
							<MuiButton onClick={() => history.goBack()} startIcon={<ArrowBackIcon />}>
								Back
							</MuiButton>
						)}
					</MuiGrid>
				</MuiGrid>

				<Container
					sx={{
						background: dashboardPath ? 'transparent' : '',
						boxShadow: dashboardPath ? 'none' : '',
						padding: dashboardPath ? '0' : '',
						borderRadius: dashboardPath ? '0px' : '15px'
					}}
				>
					{children}
				</Container>
			</MuiBox>
		);
	}
}
