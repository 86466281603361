import { Component } from 'react';
import { Loader } from 'Components';
import { MuiBox } from 'Components/MUI';

export default class Fallback extends Component {

	public render() {
		return (
			<MuiBox sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				height: '100vh',
				background: '#fff',
				zIndex: 99999,
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				overflow: 'hidden'
			}}
			>
				<Loader />
			</MuiBox>
		);
	}
}
