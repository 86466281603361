import { Component, ReactNode } from 'react';
import { MuiBox, MuiTabs, MuiTab, MuiHidden } from 'Components/MUI';
import { States } from '../../Redux/Models';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<MuiBox role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other} style={{ minWidth: 'calc(100% - 140px)' }}>
			{value === index && (
				<MuiBox
					sx={[
						(theme) => ({
							...theme.components?.MuiButton?.defaultProps?.sx,
							[theme.breakpoints.down('sm')]: {
								p: 0,
								pt: 3
							}
						}),
						{ p: 3 }
					]}
				>
					{children}
				</MuiBox>
			)}
		</MuiBox>
	);
}

export default class MuiVerticalTabs extends Component<any> {
	public state: States = {
		tabValue: 0
	};
	public handleChange = (event: React.SyntheticEvent, newTabValue: number): void => {
		this.setState((prevState: States) => (prevState.tabValue = newTabValue));
	};
	public render(): ReactNode {
		const { tabValue } = this.state;
		const { tabs } = this.props;
		return (
			<MuiBox sx={{ width: '100%' }}>
				{/* start vertical tabs for desktop */}
				<MuiHidden mdDown={true}>
					<MuiBox sx={{ flexGrow: 1, display: 'flex' }}>
						<MuiTabs value={tabValue} onChange={this.handleChange} aria-label="navTabs" orientation="vertical" variant="scrollable" sx={{ borderRight: 1, borderColor: 'divider', minWidth: '160px' }}>
							{tabs.map((item: any, key: number) => (
								<MuiTab
									label={item.label}
									key={key}
									sx={{
										fontWeight: 700,
										textTransform: 'capitalize',
										fontSize: '16px',
										alignItems: 'flex-start',
										textAlign: 'left'
									}}
								/>
							))}
						</MuiTabs>
						{tabs.map((item: any, key: number) => (
							<TabPanel value={tabValue} index={key} key={key}>
								{item.Component}
							</TabPanel>
						))}
					</MuiBox>
				</MuiHidden>
				{/* end vertical tab for desktop */}

				{/* start horizontal tab for mobile */}
				<MuiHidden mdUp={true}>
					<MuiBox>
						<MuiBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<MuiTabs
								value={tabValue}
								onChange={this.handleChange}
								aria-label="reports tabs"
								variant="scrollable"
								scrollButtons
								allowScrollButtonsMobile
								sx={{
									'& button': {
										fontWeight: 700,
										textTransform: 'capitalize',
										fontSize: '16px'
									},
									'& .MuiTabScrollButton-root': {
										'&.Mui-disabled': { opacity: 0.3 }
									}
								}}
							>
								{tabs.map((item: any, key: number) => (
									<MuiTab
										label={item.label}
										key={key}
										sx={{
											fontWeight: 700,
											textTransform: 'capitalize',
											fontSize: '16px',
											alignItems: 'flex-start'
										}}
									/>
								))}
							</MuiTabs>
						</MuiBox>

						{tabs.map((item: any, key: number) => (
							<TabPanel value={tabValue} index={key} key={key}>
								{item.Component}
							</TabPanel>
						))}
					</MuiBox>
				</MuiHidden>
				{/* end horizontal tab for mobile */}
			</MuiBox>
		);
	}
}
