import { Component, ReactNode } from 'react';
import { MenuItem, Select, SelectProps, FormControl, InputLabel } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { Map } from 'Helpers/Array';
import { GetValue } from 'Helpers/Object';

interface SelectListProps<T> extends SelectProps {
    items?: Array<T>;
    titlefield?: string;
    valuefield?: string;
    isallselected?: boolean;
}

class MuiSelectAllField<T extends {}> extends Component<SelectListProps<T>> {
    public render(): ReactNode {

        const { items, titlefield = 'label', valuefield = 'value', label, error = false, isallselected } = this.props;
        return (
            <FormControl fullWidth size="small" error={error}>
                <InputLabel id="selectLabel">{label || ''}</InputLabel>
                <Select variant='outlined' labelId="selectLabel" fullWidth {...this.props} className={'multiSelectFieldMainWrap'}>
                    {items && items.length > 0 && (
                        <MenuItem value="all" className={isallselected ? 'Mui-selected' : ''} sx={[
                            (theme: Theme) => ({
                                px: 3.5,
                                whiteSpace: 'break-spaces',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 2,
                                [theme.breakpoints.down("sm")]: {
                                    minHeight: 35,
                                },
                                '&:not(:last-child)': {
                                    mb: '5px',
                                },
                                '&.Mui-selected': {
                                    position: 'relative',
                                    backgroundColor: theme.palette.primary.light,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.light,
                                    },
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: 13,
                                        left: 11,
                                        width: 7,
                                        height: 12,
                                        transform: 'rotate(45deg)',
                                        borderBottom: `2px solid ${theme.palette.primary.main}`,
                                        borderRight: `2px solid ${theme.palette.primary.main}`,
                                    },
                                },
                            })
                        ]}>Select All</MenuItem>
                    )}
                    {items && items.length > 0 ? (
                        Map(items, (option, key) => (
                            <MenuItem
                                key={key}
                                value={GetValue(option, valuefield)}
                                sx={[
                                    (theme: Theme) => ({
                                        px: 3.5,
                                        whiteSpace: 'break-spaces',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                        [theme.breakpoints.down("sm")]: {
                                            minHeight: 35,
                                        },
                                        '&:not(:last-child)': {
                                            mb: '5px',
                                        },
                                        '&.Mui-selected': {
                                            position: 'relative',
                                            backgroundColor: theme.palette.primary.light,
                                            '&:hover': {
                                                backgroundColor: theme.palette.primary.light,
                                            },
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 13,
                                                left: 11,
                                                width: 7,
                                                height: 12,
                                                transform: 'rotate(45deg)',
                                                borderBottom: `2px solid ${theme.palette.primary.main}`,
                                                borderRight: `2px solid ${theme.palette.primary.main}`,
                                            },
                                        },
                                    })
                                ]}
                            >
                                {GetValue(option, titlefield)}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem value="" sx={{ pointerEvents: 'none', color: '#000' }} disabled={true}>
                            No Data Found
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

}

export default MuiSelectAllField;