import { MuiBox, MuiButton, MuiGrid, MuiIconButton, MuiTypography } from 'Components/MUI';
import { Component, ReactNode } from 'react';
import { DialogTitle, DialogContent, DialogProps } from '@mui/material';
import { StyleDialog } from './styled';
import { UploadModel } from 'Redux/Models';
import { CsvUploadState } from 'Redux/States';
import { DragDropFileUploader } from 'Components/DragDropFileUploader';
import { EmployeeService } from 'Services';
import DownloadLink from 'Components/DownloadLink';
import { CloseIcon, DownloadIcon } from 'Helpers/Icons';
import { Map } from 'Helpers/Array';

class CsvUploadDialog extends Component<DialogProps, CsvUploadState> {
    public state: CsvUploadState = new CsvUploadState();

    public componentDidMount(): void {
        this.getCsvTemplate();
    }

    public getCsvTemplate = () => {
        EmployeeService.getCsvTemplate().then(resp => {
            if (resp.data) {
                this.setState((prevState: CsvUploadState) => {
                    prevState.downloadSample = resp.getData();
                    return prevState;
                })
            }
        })
    }

    public handleFileChange = (files: Array<any>) => {
        if (files) {
            this.setState((prevState: CsvUploadState) => {
                prevState.employees = files;
                return prevState;
            })
        }
    };

    public handleFileDelete = (file: UploadModel) => {
        if (file) {
            this.setState((prevState: CsvUploadState) => {
                prevState.employees = [];
                return prevState;
            })
        }
    };



    public handleSubmit = (file: UploadModel) => {
        const { onClose } = this.props;
        if (file) {
            const formData = new FormData();
            formData.append('employees', file[0])
            EmployeeService.inviteByCsv(formData).then((resp) => {
                if (resp.isSuccess()) {
                    if (onClose) {
                        if (resp.data.failedRows.length > 0) {
                            this.setState((prevState: CsvUploadState) => {
                                prevState.failedRows = resp.data?.failedRows;
                                prevState.employees = [];
                                return prevState;
                            })
                            onClose(resp.getData(), 'backdropClick');
                        } else {
                            onClose(resp.getData(), 'backdropClick');
                        }
                    }
                }
            }).catch((err) => {
                if (onClose) {
                    onClose({}, 'backdropClick');
                }
            })
        }
    }

    public render(): ReactNode {
        const { loading, employees, downloadSample, failedRows } = this.state;
        const { ...restProps } = this.props;
        const { onClose } = this.props;
        return (
            <StyleDialog {...restProps}>
                <DialogTitle>
                    Upload Employee CSV
                    {onClose &&
                        <MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
                            <CloseIcon />
                        </MuiIconButton>
                    }
                </DialogTitle>
                <DialogContent sx={{ mt: 1, pt: '8px !important' }}>
                    <MuiGrid container spacing={2}>
                        <MuiGrid item xs={12}>
                            <DragDropFileUploader
                                files={employees}
                                allowedTypes={['text/csv']}
                                loading={loading}
                                onChange={this.handleFileChange}
                                onDelete={this.handleFileDelete}
                            ></DragDropFileUploader>
                        </MuiGrid>
                        <MuiGrid item xs={12} mb={4}>
                            {downloadSample.path &&
                                <MuiBox sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}>
                                    <DownloadLink link={downloadSample?.path}>
                                        {'Download Sample CSV'}
                                    </DownloadLink>
                                    <DownloadLink link={downloadSample?.path}>
                                        <MuiIconButton size="medium">
                                            <DownloadIcon />
                                        </MuiIconButton>
                                    </DownloadLink>
                                </MuiBox>
                            }
                        </MuiGrid>
                        <MuiGrid item xs={12}>
                            <MuiButton
                                onClick={() => this.handleSubmit(employees)}
                                disabled={employees.length > 0 ? false : true}
                                loading={loading}
                                variant="contained"
                                fullWidth={true}
                            >
                                Upload CSV
                            </MuiButton>
                        </MuiGrid>
                        {failedRows.length > 0 && <MuiGrid item xs={12} mt={2.5} mb={1.5}>
                            <MuiTypography variant='subtitle2' sx={{
                                color: 'red',
                                '& span:last-child': {
                                    '& span': {
                                        display: 'none'
                                    }
                                }
                            }}
                            >Failed to import rows: &nbsp;
                                {Map(failedRows, (item: number, index: number) => (
                                    <MuiTypography key={index} component='span' sx={{ display: 'inline-block', color: 'black' }}>
                                        {item}<span>,</span>
                                    </MuiTypography>
                                ))}
                            </MuiTypography>

                        </MuiGrid>}
                    </MuiGrid>
                </DialogContent>
            </StyleDialog >
        );
    }
}
export default CsvUploadDialog;
