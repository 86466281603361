import BaseService from './BaseService';
import { Http } from '../Http';
import { Options, Response, ResponseList, UserModel } from '../Redux/Models';

export default class UserService extends BaseService {
	public static getList(params: Object = {}, options: Options = {}): Promise<ResponseList<UserModel>> {
		let url: string = '/users';
		if (options?.root) {
			url = options.root + url;
		}
		UserService.initCancelToken();
		if (options?.source) {
			UserService.source = options?.source;
		}
		return new Promise((resolve, reject) => {
			Http.get(url, { params, cancelToken: UserService.source?.token })
				.then((res) => {
					let data = new ResponseList<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static getUserById(id: number, params: Object = {}): Promise<Response<UserModel>> {
		UserService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.get('/users/' + id, { params, cancelToken: UserService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static create(params: Object = {}): Promise<Response<UserModel>> {
		UserService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.post('/users', params, { cancelToken: UserService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static update(id: number, params: Object = {}): Promise<Response<UserModel>> {
		UserService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.patch('/users/' + id, params, { cancelToken: UserService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static updateStatus(id: number, params: Object = {}): Promise<Response<UserModel>> {
		UserService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.put('/users/' + id, params, { cancelToken: UserService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}

	public static delete(id: number): Promise<Response<UserModel>> {
		UserService.initCancelToken();
		return new Promise((resolve, reject) => {
			Http.delete('/users/' + id, { cancelToken: UserService.source?.token })
				.then((res) => {
					let data = new Response<UserModel>(res.data);
					return resolve(data);
				})
				.catch((err) => {
					return reject(err);
				});
		});
	}
}
