import { BaseModel } from './Base.Models';
import { UserModel } from './User.Models';

export enum PERMISSION {
  VIEW = 'VIEW',
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  INVITE = 'INVITE'
}

export enum ROLE {
  SUPER_ADMIN = 'SUPER_ADMIN',
  CLIENT = 'CLIENT',
  CONTRACTOR = 'CONTRACTOR',
  USER = 'USER',
  EMPLOYEE = 'EMPLOYEE',
  SUB_USER = 'SUB_USER',
}

export enum MODULE {
  REPORTS = 'REPORTS',
  USER_MANAGEMENT = 'USER_MANAGEMENT',
  ROLE_MANAGEMENT = 'ROLE_MANAGEMENT',
  CLIENT_MANAGEMENT = 'CLIENT_MANAGEMENT',
  LOCATION_MANAGEMENT = 'LOCATION_MANAGEMENT',
  SERVICE_MANAGEMENT = 'SERVICE_MANAGEMENT',
  CONTRACTOR_MANAGEMENT = 'CONTRACTOR_MANAGEMENT',
  BROADCAST_MANAGEMENT = 'BROADCAST_MANAGEMENT',
  EMPLOYEE_MANAGEMENT = 'EMPLOYEE_MANAGEMENT',
  SERVICE_HISTORY_MANAGEMENT = 'SERVICE_HISTORY_MANAGEMENT',
  ACTIVITY_LOG_MANAGEMENT = 'ACTIVITY_LOG_MANAGEMENT',
  PLAN_MANAGEMENT = 'PLAN_MANAGEMENT'
}

export class RoleModel extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public name!: string;
  public permissions!: Array<RoleHasPermission>;
  public updatedAt?: string | undefined;
  public slug?: ROLE;
  public users?: UserModel[];
}

export class RoleHasPermission {
  public id?: number;
  public moduleId!: number;
  public permissionId!: number;
  public module?: ModuleModel;
  public permission?: PermissionModel;
}

export class PermissionModel extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public id!: number;
  public name!: string;
  public slug!: PERMISSION;
}

export class AddRoleModel {
  public name!: string;
  public permissions!: {
    [k: string]: {
      [k: string]: boolean
    }
  }
}
export class ModuleModel extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public id!: number;
  public name!: string;
  public slug!: MODULE;
  public permissions?: Array<PermissionModel>;
}

export class ModuleWithPermission extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public id!: number;
  public name!: string;
  public slug!: PERMISSION;
  public moduleId!: number;
  public permissions!: PermissionModel[];
}

export class RoleDetailModel extends BaseModel {
  public constructor(params = {}) {
    super();
    Object.assign(this, params);
  }
  public name!: string;
  public modules!: ModuleWithPermission[];
}
