import BaseService from "./BaseService";
import { Http } from "../Http";
import { SubscriptionModel, Response, PlanModel } from "Redux/Models";

export default class SubscriptionService extends BaseService {
  public static create(planId: number): Promise<Response<SubscriptionModel>> {
    SubscriptionService.initCancelToken();
    return new Promise((resolve, reject) => {
      Http.post("/subscriptions", { planId })
        .then((res) => {
          let data = new Response<SubscriptionModel>(res.data);
          return resolve(data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  public static getPlans(params = {}): Promise<Response<Array<PlanModel>>> {
    SubscriptionService.initCancelToken();
    return new Promise((resolve, reject) => {
      Http.get("/plans", {
        params,
        cancelToken: SubscriptionService.source?.token,
      })
        .then((res) => {
          let data = new Response<Array<PlanModel>>(res.data);
          return resolve(data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  public static getActiveSubcription(
    params = {}
  ): Promise<Response<SubscriptionModel>> {
    SubscriptionService.initCancelToken();
    return new Promise((resolve, reject) => {
      Http.get("/subscriptions/active", {
        params,
        cancelToken: SubscriptionService.source?.token,
      })
        .then((res) => {
          let data = new Response<SubscriptionModel>(res.data);
          return resolve(data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  public static upgrade(object: Object): Promise<Response<SubscriptionModel>> {
    SubscriptionService.initCancelToken();
    return new Promise((resolve, reject) => {
      Http.patch("/subscriptions", object)
        .then((res) => {
          let data = new Response<SubscriptionModel>(res.data);
          return resolve(data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }

  public static updateSubscription(id: number, params: Object = {}): Promise<Response<PlanModel>> {
    SubscriptionService.initCancelToken();
    return new Promise((resolve, reject) => {
      Http.put(`plans/${id}`, params).then((res) => {
        let data = new Response<PlanModel>(res.data);
        return resolve(data);
      })
        .catch((err) => {
          return reject(err);
        })
    })
  }
}
