import { GetDateTime, GetHourDifference } from 'Helpers/String';
import { BaseStateModel, DataTableModel, BroadCastModel, LocationModel } from '../Models';

export class DashboardState extends BaseStateModel {
	public notification: Object = { title: "", body: "" };
	public modalOpen: boolean = false;
	public loading: boolean = false;
	public tab: number = 0;
	public total: number = 0;
	public metrics: Object = {};
	public metricSort: number = 1;
	public notifications: BroadCastModel[] = [];
	public mapLocations: Array<LocationModel> = [];
	public dataTable: DataTableModel = {
		searchable: true,
		pagination: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'service-history?with[]=creator.company&with[]=assignedUser&with[]=service&with[]=location&with[]=creator.parents',
		headers: [
			{ key: 'location.name', label: 'Location', isSort: false, },
			{ key: 'task', label: 'Task', isSort: true },
			{ key: 'service.name', label: 'Service Type', isSort: false, },
			{ key: 'assignedUser.name', label: 'Employee Name', isSort: false, render: (item: any) => `${item.assignedUser.firstName} ${item.assignedUser.lastName}` },
			{ key: 'creator.company.name', label: 'Contractor Name', isSort: false, render: (item: any) => `${item.creator.firstName} ${item.creator.lastName}` },
			{ key: 'creator.company.parents', label: 'Client Name(s)', isSort: false, render: (item: any) => `${item.creator.parents.map((p: any) => p.name).join(',')}` },
			{ key: 'checkIn', label: 'Check In', isSort: true, render: (item: any) => GetDateTime(item.checkIn) },
			{ key: 'checkOut', label: 'Check Out', isSort: true, render: (item: any) => GetDateTime(item.checkOut) },
			{ key: 'induction', label: 'Induction', isSort: true },
			{ key: 'timeOnsite', label: 'Time Onsite', isSort: true, render: (item: any) => GetHourDifference(item.checkIn, item.checkOut) },
		],
		filters: [
			{ key: 'serviceId', name: 'Service Type', items: [], multiple: true },
			{ key: 'locationId', name: 'Locations', items: [], multiple: true },
		]
	};
}
