import { MuiBox, MuiTypography, MuiAvatar, MuiIconButton } from 'Components/MUI';
import { Component, ReactNode } from 'react';
import { CameraAltIcon, DeleteIcon } from 'Helpers/Icons';
import { Theme } from '@mui/material/styles';
import { MuiStyledBadge } from './styled';

interface Props {
	src?: string;
	onChange?: Function;
	onDelete?: Function;
	active?: boolean;
}

export default class MuiUploadAvatar extends Component<Props> {
	public handleUpload = (): void => {
		const element = document.createElement('input');
		element.type = 'file';
		element.addEventListener('change', (e: any) => {
			if (this.props.onChange) {
				this.props.onChange(e.target.files);
			}
		});
		element.click();
	};

	public onDelete = (): void => {
		const { onDelete } = this.props;
		if (onDelete) {
			onDelete();
		}
	};

	public render(): ReactNode {
		const { src, active } = this.props;
		return (
			<MuiStyledBadge overlap="circular" anchorOrigin={{ vertical: 'top', horizontal: 'left' }} variant="dot" active={!!active}>
				<MuiAvatar
					sx={[
						(theme: Theme) => ({
							backgroundColor: theme.palette.primary.main,
							width: 190,
							height: 190
						})
					]}
					src={src}
				/>
				<MuiBox onClick={this.handleUpload}>
					<CameraAltIcon />
					<MuiTypography
						sx={{
							color: '#fff'
						}}
					>
						Upload profile image
					</MuiTypography>
				</MuiBox>
				{!!src && (
					<MuiIconButton
						onClick={this.onDelete}
						sx={{
							'position': 'absolute',
							'bottom': 5,
							'right': 15,
							'backgroundColor': 'error.light',
							'color': 'primary.contrastText',
							'&:hover': {
								backgroundColor: 'error.main',
								color: 'primary.contrastText'
							}
						}}
					>
						<DeleteIcon />
					</MuiIconButton>
				)}
			</MuiStyledBadge>
		);
	}
}
