import { MuiBox, MuiIconButton, MuiTypography, } from 'Components/MUI';
import { Component, ReactNode } from 'react';
import { DialogTitle, DialogContent, DialogProps } from '@mui/material';
import { StyleDialog } from './styled';
import { UploadModel } from 'Redux/Models';
import { UploadedFilesDialogState } from 'Redux/States';
import { CloseIcon, DownloadIcon, } from 'Helpers/Icons';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { GetDate } from 'Helpers/String';
import DownloadLink from 'Components/DownloadLink';
import { Map } from 'Helpers/Array';

interface ModalPropsType extends DialogProps {
	files: { insurences: UploadModel[], licenses: UploadModel[] } | null,
}


class UploadedFilesDialog extends Component<ModalPropsType, UploadedFilesDialogState> {
	public state: UploadedFilesDialogState = new UploadedFilesDialogState();

	public handleChange = (event: React.SyntheticEvent, newValue: '1' | '2') => {
		this.setState((prevState: UploadedFilesDialogState) => {
			prevState.tab = newValue
			return prevState
		})
	}


	public render(): ReactNode {
		const { tab, } = this.state;
		const { files, onClose, ...restProps } = this.props;
		const insurences = files?.insurences || []
		const licenses = files?.licenses || []

		return (
			<StyleDialog {...restProps} onClose={onClose}>
				<DialogTitle >
					{(insurences.length + licenses.length) || 0} {'Uploaded file(s)'}
					{onClose &&
						<MuiIconButton onClick={() => onClose({}, 'backdropClick')}>
							<CloseIcon />
						</MuiIconButton>
					}
				</DialogTitle>
				<DialogContent sx={{ mt: 1, pt: '8px !important' }}>
					<TabContext value={tab}>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<TabList onChange={this.handleChange} aria-label="Files tabs">
								<Tab sx={{ textTransform: 'capitalize' }} label="Licenses" value="1" />
								<Tab sx={{ textTransform: 'capitalize' }} label="Insurences" value="2" />
							</TabList>
						</Box>
						<TabPanel value="1" sx={{ padding: '10px' }}>
							{licenses && licenses.length > 0 ? Map(licenses, (license: UploadModel, licIndex: number) => (
								<MuiBox key={licIndex} sx={{
									backgroundColor: '#f9f9f9',
									padding: '10px 15px',
									borderRadius: '5px',
									mt: 1,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									'& a': {
										fontSize: 16,
										fontWeight: 600,
										display: 'block',
										color: 'primary.main',
										width: 'fit-content',
										'&:hover': {
											color: 'primary.dark',
										}
									},
									'& p': {
										fontSize: 16,
										mt: 1,
										mb: 0,
									}
								}}>
									<MuiBox >
										<DownloadLink link={license.path!}>{license?.originalName!}</DownloadLink>
										<p>Expiry Date: {GetDate(license.expDate)}</p>
									</MuiBox>
									<DownloadLink link={license.path!}>
										<MuiIconButton size="medium">
											<DownloadIcon />
										</MuiIconButton>
									</DownloadLink>
								</MuiBox>
							)) : <MuiTypography sx={{ textAlign: 'center', padding: '30px' }}>No License Found</MuiTypography>}
						</TabPanel>
						<TabPanel value="2" sx={{ padding: '10px' }}>
							{insurences && insurences.length > 0 ? Map(insurences, (insurence: UploadModel, inIndex: number) => (
								<MuiBox key={inIndex} sx={{
									backgroundColor: '#f9f9f9',
									padding: '10px 15px',
									borderRadius: '5px',
									mt: 1,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									'& a': {
										fontSize: 16,
										fontWeight: 600,
										display: 'block',
										color: 'primary.main',
										width: 'fit-content',
										'&:hover': {
											color: 'primary.dark',
										}
									},
									'& p': {
										fontSize: 16,
										mt: 1,
										mb: 0,
									}
								}}>
									<MuiBox >
										<DownloadLink link={insurence.path!}>{insurence?.originalName!}</DownloadLink>
										<p>Expiry Date: {GetDate(insurence.expDate)}</p>
									</MuiBox>
									<DownloadLink link={insurence.path!}>
										<MuiIconButton size="medium">
											<DownloadIcon />
										</MuiIconButton>
									</DownloadLink>
								</MuiBox>
							)) : <MuiTypography sx={{ textAlign: 'center', padding: '30px' }}>No Insurence Found</MuiTypography>}
						</TabPanel>
					</TabContext>
				</DialogContent>
			</StyleDialog>
		);
	}
}
export default UploadedFilesDialog;