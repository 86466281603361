import { GetDateTime } from 'Helpers/String';
import { BaseStateModel, DataTableModel, ServiceModel } from '../Models';

export class ServiceListState extends BaseStateModel {
	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'id',
		direction: 'desc',
		actions: [],
		url: 'services',
		headers: [
			{ key: 'name', label: 'Name', isSort: true },
			{
				key: 'updatedAt',
				label: 'Last Updated',
				isSort: true,
				render: (item: ServiceModel) => GetDateTime(item.updatedAt)
			}
		]
	};
}

export class ServiceAddState extends BaseStateModel {
	public loading: boolean = false;
	public service: ServiceModel = {
		name: '',
		details: ''
	};
}

export class ServiceDetailState extends BaseStateModel {
	public loading: boolean = false;
	public service: ServiceModel = {
		name: '',
		details: ''
	};
}
