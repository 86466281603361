/* eslint-disable import/no-webpack-loader-syntax */
import { MB_TOKEN } from "Configs";
import { Map } from "Helpers/Array";
import React from "react";
import InteractiveMap, { Marker, NavigationControl, Popup } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapMarker } from "Helpers/Images";
import { Link } from "react-router-dom";
import { MuiBox, MuiTypography } from "Components/MUI";
import { LocationModel } from "Redux/Models";
import mapboxgl from "mapbox-gl";

// @ts-ignore
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

class Mark {
    id?: number;
    mapId?: string;
    latitude?: number;
    longitude?: number;
    address?: number;
    name?: string;
}

const DashBoardMap = (props: LocationModel) => {
    const [markers, setMarkers]: any = React.useState([]);
    const [popups, setPopups]: any = React.useState(null);
    const [viewport, setViewport]: any = React.useState({
        latitude: 22.585393,
        longitude: 88.408537,
        zoom: 1,
        width: 100,
        height: 100
    });

    React.useEffect(() => {
        const { mapLocations } = props;
        let locationArr: Array<LocationModel | []> = [];
        if (mapLocations.length) {
            Map(mapLocations, (location: LocationModel, index: number) => {
                locationArr.push({
                    id: location.id,
                    mapId: location.mapId,
                    latitude: location.lat,
                    longitude: location.lon,
                    address: location.address,
                    name: location.name
                });
            })
        }
        setMarkers(locationArr);
    }, [props]);


    return (
        <InteractiveMap
            {...viewport}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={MB_TOKEN}
            initialViewState={viewport}
            onMove={(value) => setViewport(value.viewState)}
            attributionControl={false}
        >
            {markers && Map(markers, (mark: Mark, index: number) => (
                <Marker
                    {...mark}
                    key={index}
                    onClick={(event) => {
                        event.originalEvent.stopPropagation();
                        setPopups(mark);
                    }}
                >
                    <img
                        src={MapMarker}
                        style={{
                            width: "25px"
                        }}
                        alt="test"
                    />
                </Marker>
            ))}
            {popups &&
                <Popup anchor="right" onClose={() => setPopups(null)} {...popups}>
                    <MuiBox mt={1} sx={{
                        '& h4': {
                            color: 'secondary.dark',
                            fontWeight: 600,
                            fontSize: 14,
                            '& span': {
                                fontWeight: 500,
                                color: 'info.contrastText',
                                ml: 1,
                            },
                            '& a': {
                                color: 'primary.main',
                                textDecoration: 'none',
                                ml: 1,
                                outline: 'none !important',
                                boxShadow: 'none !important',
                                '&:hover': {
                                    color: 'primary.dark',
                                }
                            }
                        }
                    }}>
                        <MuiTypography variant="caption" component="h4">
                            Location Address: <Link to={popups.id ? `/locations/${popups.id}` : '/dashboard'}>{popups.address || ''}</Link>
                        </MuiTypography>
                        <MuiTypography variant="caption" component="h4">
                            Location Name: <span>{popups.name || ''}</span>
                        </MuiTypography>
                    </MuiBox>
                </Popup>}
            <NavigationControl />
        </InteractiveMap>
    )
}

export default DashBoardMap;