import * as firebase from 'firebase/app';
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken,
} from 'firebase/messaging';
import { FIREBASE_CONFIG, vapidKey } from '../Configs/index';

export const firebaseApp = firebase.initializeApp(FIREBASE_CONFIG);
export const messaging = getMessaging(firebaseApp);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
export const getDeviceToken = (): Promise<string> => {
  return new Promise((resolve, rejected) => {
    getToken(messaging, { vapidKey })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken);
          // Send the token to your server and update the UI if necessary
        } else {
          // Show permission request UI
          // console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        // console.error(err.message);
        rejected(err);
      });
  });
};

export const deleteDeviceToken = () => deleteToken(messaging);
// .then(result => console.log(`FCM deleted => ${result}`))
// .catch((err) => console.error(err.message))

export const onMsgListner = () => {
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('Message received. ', payload);
      resolve(payload);
      // ...
    });
  });
};
