import { MuiLink, MuiTooltip } from "Components/MUI";
import { getFullPath } from "Helpers/String";
import { Component, ReactNode } from "react";


export default class DownloadLink extends Component<{ link: string, children: any }> {

    public download = () => {
        if (this.props.link) {
            const a = document.createElement('a');
            a.download = this.props.children;
            a.href = getFullPath(this.props.link)!;
            a.target = "_blank";
            a.click();
        }
    }

    public render(): ReactNode {
        return <MuiTooltip title="Click to download">
            <MuiLink onClick={this.download} sx={{
                cursor: 'pointer',
                ":hover": {
                    color: '#8DCBDA'
                }
            }}> {this.props.children} </MuiLink>
        </MuiTooltip>
    }
}