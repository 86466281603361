import { SvgIconComponent } from '@mui/icons-material';
import {
  DashboardIcon,
  ManageAccountsIcon,
  GroupIcon,
  MiscellaneousServicesIcon,
  CellTowerIcon,
  ReportIcon,
  EngineeringIcon,
  AccountTreeIcon,
  FiberManualRecordIcon,
  AttachMoneyIcon,
} from 'Helpers/Icons';
import { MODULE } from 'Redux/Models';
export class Menu {
  public path!: string;
  public icon!: SvgIconComponent;
  public module!: MODULE | Array<MODULE>;
  public title!: string;
  public active!: string;
  public childrens?: Array<Menu>;
}

export const MENUES: Array<Menu> = [
  {
    path: '/dashboard',
    module: [],
    icon: DashboardIcon,
    title: 'Dashboard',
    active: 'active',
  },
  {
    path: '/clients',
    module: MODULE.CLIENT_MANAGEMENT,
    icon: GroupIcon,
    title: 'Client Organization',
    active: 'active',
  },
  {
    path: '',
    module: [MODULE.USER_MANAGEMENT, MODULE.ROLE_MANAGEMENT],
    icon: ManageAccountsIcon,
    title: 'Staff Management',
    active: 'active',
    childrens: [
      {
        path: '/roles',
        module: MODULE.ROLE_MANAGEMENT,
        icon: FiberManualRecordIcon,
        title: 'Roles',
        active: 'active',
      },
      {
        path: '/users',
        module: MODULE.USER_MANAGEMENT,
        icon: FiberManualRecordIcon,
        title: 'Users',
        active: 'active',
      },
    ],
  },
  {
    path: '/services',
    module: MODULE.SERVICE_MANAGEMENT,
    icon: MiscellaneousServicesIcon,
    title: 'Services',
    active: 'active',
  },
  {
    path: '/service-history',
    module: MODULE.SERVICE_HISTORY_MANAGEMENT,
    icon: MiscellaneousServicesIcon,
    title: 'Service History',
    active: 'active',
  },
  {
    path: '/broadcasts',
    module: MODULE.BROADCAST_MANAGEMENT,
    icon: CellTowerIcon,
    title: 'Broadcast',
    active: 'active',
  },
  {
    path: '/locations',
    module: MODULE.LOCATION_MANAGEMENT,
    icon: AccountTreeIcon,
    title: 'Locations',
    active: 'active',
  },
  {
    path: '/contractors',
    module: MODULE.CONTRACTOR_MANAGEMENT,
    icon: EngineeringIcon,
    title: 'Contractor Organization',
    active: 'active',
  },
  {
    path: '/employees',
    module: MODULE.EMPLOYEE_MANAGEMENT,
    icon: EngineeringIcon,
    title: 'Employees',
    active: 'active',
  },
  {
    path: '/reports',
    module: MODULE.REPORTS,
    icon: ReportIcon,
    title: 'Reports',
    active: 'active',
  },
  {
    path: '/subscriptions',
    module: MODULE.PLAN_MANAGEMENT,
    icon: AttachMoneyIcon,
    title: 'Subscription',
    active: 'active',
  },
];
