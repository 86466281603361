import { Dialog, styled as MuiStyle, Theme } from '@mui/material';
import { ThemeProps } from 'styled-components';

export const StyleDialog = MuiStyle(Dialog)(({ theme }: ThemeProps<Theme>) => ({
    '.MuiDialogTitle-root': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    '& .MuiPaper-root': {
        width: 700,
        [theme.breakpoints.down('md')]: {
            width: 550,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    }
}));
