import { GetDateTime } from 'Helpers/String';
import { BaseStateModel, DataTableModel, ModuleModel, AddRoleModel, RoleDetailModel, RoleModel, ModuleWithPermission } from '../Models';

export class AddRoleState extends BaseStateModel {
	public loading: boolean = false;
	public hasError: boolean = false;
	public errorMessage: string = '';
	public role: AddRoleModel = {
		name: '',
		permissions: {}
	};
	public permissions: Array<ModuleWithPermission> = [];
	public modules: Array<ModuleModel> = [];
	public isCheckOTP: boolean = false;
}

export class RoleListState extends BaseStateModel {
	public modalOpen: boolean = false;
	public dataTable: DataTableModel = {
		searchable: true,
		pagination: true,
		limit: 10,
		active: 'createdAt',
		direction: 'desc',
		actions: [],
		url: 'roles?with[]=users',
		headers: [
			{ key: 'name', label: 'Role', isSort: true },
			{
				key: 'users',
				label: 'Associated users',
				isSort: false,
				render: (item: RoleModel) => item?.users?.length
			},
			{
				key: 'updatedAt',
				label: 'Last updated',
				isSort: true,
				render: (item: RoleModel) => GetDateTime(item.updatedAt)
			}
		],
		filters: [
			{
				name: 'Status',
				key: 'status',
				items: [
					{ label: 'Active', value: 'ACTIVE' },
					{ label: 'Inactive', value: 'INACTIVE' }
				],
				multiple: false
			}
		]
	};
}

export class RoleDetailState extends BaseStateModel {
	public loading: boolean = false;
	public role: RoleDetailModel = {
		name: '',
		modules: []
	};
}
