import { GetDateTime } from 'Helpers/String';
import { BaseStateModel, BroadCastModel, DataTableModel, RoleModel, UserModel } from '../Models';

export class BroadCastListState extends BaseStateModel {
	public broadcastList: BroadCastModel[] = []
	public dataTable: DataTableModel = {
		pagination: true,
		searchable: true,
		limit: 10,
		active: 'id',
		direction: 'desc',
		actions: [],
		url: 'broadcasts',
		headers: [
			{ key: 'name', label: 'Name', isSort: true },
			{
				key: 'updatedAt',
				label: 'Last Updated',
				isSort: true,
				render: (item: BroadCastModel) => GetDateTime(item.updatedAt)
			}
		]
	};
}

export class BroadCastAddState extends BaseStateModel {
	public loading: boolean = false;
	public roleList: RoleModel[] = []
	public userList: UserModel[] = []
	public isAllSelected: boolean = false;
	public broadcast: BroadCastModel = {
		title: '',
		description: '',
		roles: [],
		users: [],
	};
}

export class NotificationListState extends BaseStateModel {
	public loading: boolean = false;
	public total: number = 0;
	public notifications: BroadCastModel[] = []
}